import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SvgService} from '../../../services/svg.service';
import {DataService} from '../../../services/data.service';
import {AuthService} from '../../../services/auth.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import { ThemesModel } from '../../../models/themes.model';
import {TradesMarket} from '../../../models/trades.model';

@Component({
  selector: 'app-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.scss']
})
export class TradesComponent implements OnInit, OnDestroy {
  @Input() public isMinimized: boolean;
  @Output() public minimized = new EventEmitter<any>();
  public isLightTheme = false;
  public marketHistory: Array<TradesMarket>;
  public isVisibleActualPair = false;
  public isGeoBlockedCountry = false;
  public ThemesModel = ThemesModel;
  destroySubject$: Subject<void> = new Subject();

  constructor(public svgService: SvgService,
              public dataService: DataService,
              public authService: AuthService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnInit() {
    this.initialDataLoading();

    this.dataService.getMarketPairNewEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.isVisibleActualPair = true;
        this.initialDataLoading();
        setTimeout(() => this.isVisibleActualPair = false, 2000);
      });

    this.dataService.joinRoom(); //   trades
    if (this.authService.isLoggedIn) {
      if (this.dataService.getUserId()) { //   trades
        this.dataService.joinRoomUserId('' + this.dataService.getUserId());
      } else { //   trades
        this.dataService.getUserProfileFromServer();
        this.dataService.getUserIdEmitter()
          .pipe(takeUntil(this.destroySubject$))
          .subscribe((userId: number) => {
            this.dataService.joinRoomUserId('' + userId);
          });
      }
    }

    this.checkGeoForBlackListedCountry();
  }

  public initialDataLoading(): void {
    const self = this;

    this.dataService.getMarketHistory()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => {
        // const time = (new Date()).getHours() + ':' + (new Date()).getMinutes() + ':' + (new Date()).getSeconds();
        // console.log('---getMarketHistory ' + time);
        self.marketHistory = res;

        self.dataService.SubscribeMarketHistory()
          .pipe(takeUntil(this.destroySubject$))
          .subscribe((data: Array<any>) => {
          // console.log('...new trades:', data);
          if (data) {
            this.marketHistory = [...data, ...this.marketHistory];
          }
        });
      }, error1 => {
        console.log(error1);
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public blockUserActivity() {
    // this.router.navigate(['/login']);
    // this.dataService.setLoginIncomeErrorStatus(['isGeoBlockedCountry']);
    // this.dataService.passLoginIncomeErrorStatus(['isGeoBlockedCountry']);
    // this.authService.logout();
  }

  public checkGeoForBlackListedCountry() {
    // start GEO blocking
    let isGeoCountryIsInBlackList: boolean;
    let isGeoCountryIsInRestricktedList: boolean;
    let userIsoCountry = this.dataService.getUserIsoCountry();
    if (userIsoCountry && userIsoCountry.length) {
      this.dataService.doVerifyIfGeoCountryIsInBlackListAndInRestricktedList(userIsoCountry);
      isGeoCountryIsInBlackList = this.dataService.getIsGeoCountryIsInBlackList();
      isGeoCountryIsInRestricktedList = this.dataService.getIsGeoCountryIsInRestricktedList();
      // console.log('isGeoCountryIsInBlackList', isGeoCountryIsInBlackList,);
      // console.log('isGeoCountryIsInRestricktedList', isGeoCountryIsInRestricktedList);
      if (isGeoCountryIsInBlackList) {
        this.isGeoBlockedCountry = true;
        this.blockUserActivity();
      }
    } else {
      this.dataService.getUserIsoCountryEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response1 => {
          userIsoCountry = response1;
          if (userIsoCountry && userIsoCountry.length) {
            this.dataService.doVerifyIfGeoCountryIsInBlackListAndInRestricktedList(userIsoCountry);
            isGeoCountryIsInBlackList = this.dataService.getIsGeoCountryIsInBlackList();
            isGeoCountryIsInRestricktedList = this.dataService.getIsGeoCountryIsInRestricktedList();
            // console.log('isGeoCountryIsInBlackList', isGeoCountryIsInBlackList);
            // console.log('isGeoCountryIsInRestricktedList', isGeoCountryIsInRestricktedList);
            if (isGeoCountryIsInBlackList) {
              this.isGeoBlockedCountry = true;
              this.blockUserActivity();
            }
          }
        });
      this.dataService.getIsGeoCountryIsInBlackListEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response2 => {
          isGeoCountryIsInBlackList = response2;
          // console.log('isGeoCountryIsInBlackList', isGeoCountryIsInBlackList);
          if (isGeoCountryIsInBlackList) {
            this.isGeoBlockedCountry = true;
            this.blockUserActivity();
          }
        });
      this.dataService.getIsGeoCountryIsInRestricktedListEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response2 => {
          isGeoCountryIsInRestricktedList = response2;
          // console.log('isGeoCountryIsInRestricktedList', isGeoCountryIsInRestricktedList);
        });
    }
    // end GEO blocking
  }


}
