import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DataService} from '../../../services/data.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {AdvcashService} from '../../../services/advcash.service';
import {CreditCardValidators} from 'angular-cc-library';
import {MyProfileInterface} from '../../../models/my-profile.model';

@Component({
  selector: 'app-advcash',
  templateUrl: './advcash.component.html',
  styleUrls: ['./advcash.component.scss']
})

export class AdvcashComponent implements OnInit {
  public advcashForm: FormGroup;
  public isLightTheme = false;
  public userId = undefined;
  destroySubject$: Subject<void> = new Subject();
  public invoiceId;
  public sign;
  public cryptoAddress;
  public iReceive;
  public minAmount;
  public sellAmount;
  public countries: Array<object>;
  public userData: MyProfileInterface;
  public advCashTooltip = `1. You may be charged an additional conversion fee. The total amount may vary due to the constantly changing rates.
  2. Min amount is 10 USD or an equivalent amount.
  3. You can buy USDT with your bank card or by direct transfer to the Advanced Cash account.
  4. After a successful payment on the partners' website, USDT will be credited to your account within a few minutes.
  5. If you have problems with your payment, please contact support service.`;
  // public step = 1;
  public methodType = 'buy';
  public isTestAccount = false;
  public withdrawalValid = true;
  public paymentMethod: null | string = localStorage.getItem('selected_payment');
  public mercuryoBuyOrSell: null | 'buy' | 'sell' = null;

  constructor(
    public dataService: DataService,
    private fb: FormBuilder,
    private router: Router,
    public advcashService: AdvcashService) {
    this.dataService.getUserProfile()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: MyProfileInterface) => {
        this.userData = res;
      });
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }

  ngOnInit(): void {
    if (!(this.dataService.getCountries() && this.dataService.getCountries().length)) {
      this.dataService.getCountriesData();
      this.dataService.getCountriesEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response => {
          this.countries = response;
        });
    } else {
      this.countries = this.dataService.getCountries();
    }
    this.createForm();
    this.getUserId();
    this.isTestAccount = [ 134, 370, 1306 ].some(x => x == this.userId);
  }

  private createForm(): void {
    this.advcashForm = this.fb.group({
      ac_currency: [localStorage.getItem('selected_currency') || 'USD', Validators.required],
      ac_ecurrency: ['BTC', Validators.required],
      ac_amount: ['', [Validators.required,
        Validators.pattern(`^\\-?([1-9]\\d*|0)(\\.\\d?[1-9])?$`), this.amountValidator()
      ]
      ],
      payment_method: ['', Validators.required],
      ac_account_email: ['', ],
      ac_sci_name: ['', ],
      credit_card: ['', CreditCardValidators.validateCCNumber],
      expiration_date: ['', CreditCardValidators.validateExpDate],
      card_holder: ['', Validators.required],
      phone_number: ['', Validators.required],
  }, null);
  }

  amountValidator() {
    return (control: FormControl) => {
      const form = control.parent;
      if (form) {
        const amount = form.get('ac_amount');
        const currency = form.get('ac_currency');

        if (currency.value === 'KZT') {
          return amount.value < 1000 ? {error: 'You have exceeded the limit'} : amount.value > 500000 ? {error: 'You have exceeded the limit'} : null;
        }
        if (currency.value === 'UAH') {
          return amount.value < 50 ? {error: 'You have exceeded the limit'} : amount.value > 14999 ? {error: 'You have exceeded the limit'} : null;
        }

        return null;
      }
    };
  }

  public async sendHiddenForm(): Promise<any> {
    const holder_country = this.countries.filter(country => (<any>country).id === this.userData.country_id)[0];
    const data = {
      ac_account_email: 'n.kalenov@biteeu.com',
      ac_sci_name: 'Biteeu SCI',
      ac_amount: this.advcashForm.value.ac_amount,
      ac_currency: this.advcashForm.value.ac_currency,
      ac_ps: this.advcashForm.value.ac_ecurrency === 'BTC' ? 'BITCOIN' : 'USDTETHER',
      ac_sign: '',
      ac_order_id: '',
      card_holder: this.advcashForm.value.card_holder,
      card_number: this.advcashForm.value.credit_card.replace(/\s/g, ''),
      card_holder_country: holder_country['name'],
      card_holder_city: this.userData.city,
      card_holder_dob: this.userData.birthday,
      card_holder_mobile_phone_number: this.advcashForm.value.phone_number,
      card_expiry_date: this.advcashForm.value.expiration_date,
      ac_status_url: 'https://api.biteeu.com/api/v1/advcash/send-money',
      ac_status_url_method: 'POST',
    };
    const sign = await this.advcashService.createPostPromise('api/v1/advcash/sci-sell', data);
    data['ac_sign'] = sign.sign;
    data['ac_order_id'] = sign.orderId;

    // const isValid = await this.advcashService.createPostPromise('api/v1/advcash/withdrawal-valid', data);

    // if (isValid.success) {
      await this.advcashService.createPostPromise('api/v1/advcash/create-withdrawal', data);
      const form = document.createElement('form');
      form.style.display = 'none';
      form.method = 'POST';
      form.action = 'https://wallet.advcash.com/sci/';
      let input;

      for (const [key, value] of Object.entries(data)) {
        input = document.createElement('input');
        input.name = key;
        input.id = key;
        input.value = value;
        form.appendChild(input);
      }

      document.body.appendChild(form);
      form.submit();
    // } else {
    //   this.withdrawalValid = isValid.success;
    //   console.log(this.withdrawalValid);
    // }
  }

  // private changeStep(): void {
  //   this.step++;
  //   document.getElementById('ac_amount_wrapper').style.content = this.advcashForm.value.ac_currency;
  // }

  public chooseMethodType(value): void {
    this.methodType = value;
  }

  public changePaymentMethod(value: 'advcash' | 'mercuryo'): void {
    this.paymentMethod = value;
  }

  public changeMercuryoBuyOrSell(value: 'buy' | 'sell'): void {
    this.mercuryoBuyOrSell = value;
  }

  private getUserId() {
    this.userId = this.dataService.getUserId();
    if (!this.userId) {
      this.dataService.getUserProfileFromServer();
    }
    this.dataService.getUserIdEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((response: number) => {
        this.userId = response;
      });
  }

  public sendAdvcash(): void {
    const paymentMethod = this.advcashForm.value.payment_method;
    if (this.methodType === 'buy') {
      const data = {
        amount: this.advcashForm.value.ac_amount,
        currency: this.advcashForm.value.ac_currency,
        coinName: this.advcashForm.value.ac_ecurrency,
        userId: this.userId,
        paymentMethod,
      };

      const minValue = {
        depositMethod: paymentMethod,
        from: 'USD',
        to: 'BTC',
        action: 'BUY',
        amount: '0.002',
      };

      this.iReceive = 'Waiting for response...';

      this.advcashService.getCurrencyExchangeRates('api/v1/advcash/currency-exchange', minValue)
        .subscribe((res) => {
          const result = res.return;
          this.minAmount = result.amountExchanged;
        });

      this.advcashService.createAdvcashInvoice('api/v1/advcash/invoice-buy', data)
        .subscribe((res) => {
          try {
            const result = res.return;
            this.invoiceId = result.id;
            this.sign = res.sign;
            this.iReceive = `${result.cryptoCurrencyAmount} ${data.coinName}`;
          } catch (e) {
            this.iReceive = 'Amount too small';
          }
        }, (err) => {
          this.iReceive = 'Amount too small';
        });
    }

    if (this.methodType === 'sell') {
      const data = {
        amount: this.advcashForm.value.ac_amount,
        currency: this.advcashForm.value.ac_currency,
        coinName: this.advcashForm.value.ac_ecurrency,
      };

      const exchangeValue = {
        depositMethod: paymentMethod,
        from: this.advcashForm.value.ac_currency,
        to: this.advcashForm.value.ac_ecurrency === 'USDT' ? 'USD' : this.advcashForm.value.ac_ecurrency,
        action: 'SELL',
        amount: this.advcashForm.value.ac_amount,
      };

      this.advcashService.getCurrencyExchangeRates('api/v1/advcash/currency-exchange', exchangeValue)
        .subscribe((res) => {
          try {
            const result = res.return;
            this.sellAmount = result.cryptoCurrencyAmountWithFee ? result.cryptoCurrencyAmountWithFee : result.amountExchanged;
          } catch (e) {
            this.sellAmount = 'Amount too small';
          }
        });

      this.iReceive = 'Waiting for response...';

      this.advcashService.createAdvcashInvoice('api/v1/advcash/invoice-sell', data)
        .subscribe((res) => {
          try {
            const result = res.return;
            this.invoiceId = result.orderId;
            this.sign = res.sign;
            this.cryptoAddress = result.address;
            this.iReceive = `${result.cryptoCurrencyAmount} ${data.coinName}`;
          } catch (e) {
            this.iReceive = 'Amount too small';
          }
        }, (err) => {
          this.iReceive = 'Amount too small';
        });
    }
    localStorage.removeItem('selected_currency');
  }

  redirectToAdvcash(): void {
    if (this.methodType === 'buy') {
      window.open(`https://wallet.advcash.com/sci/crypto-exchange?ac_account_email=n.kalenov@biteeu.com&ac_sci_name=Biteeu SCI&ac_crypto_currency_withdrawal_invoice_id=${this.invoiceId}&ac_sign=${this.sign}`);
    }
    if (this.methodType === 'sell') {
      window.open(`https://wallet.advcash.com/sci?ac_account_email=n.kalenov@biteeu.com&ac_sci_name=Biteeu SCI&ac_amount=${this.advcashForm.value.ac_amount}&ac_currency=${this.advcashForm.value.ac_currency}&ac_order_id=${this.invoiceId}&ac_sign=${this.sign}&address=${this.cryptoAddress}`);
    }
  }
}
