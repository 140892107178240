import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../../services/data.service';
import {HomeSearchPipe} from '../../../pipes/home/home-search.pipe';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-market-list',
  templateUrl: './market-list.component.html',
  styleUrls: ['./market-list.component.scss'],
  providers: [HomeSearchPipe]
})
export class MarketListComponent implements OnChanges, OnDestroy {
  @Input() marketsData;
  @Input() marketName;
  // public _marketsData: any;
  public isLightTheme = false;
  public pagination = 0;
  public marketsList;
  public searchText;
  public sortParams = {
    sortByVolume: '',
    sortByPair: '',
    sortByCurrency: '',
    sortByChange24h: '',
  };
  destroySubject$: Subject<void> = new Subject();

  constructor(public dataService: DataService,
              public router: Router
  ) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  ngOnChanges(): void {
    // this._marketsData = JSON.parse(JSON.stringify(this.marketsData));
    this.searchInMarketsList(false);
  }
  private paginationHell(data) {
    const size = 10;
    const subarray = [];
    const paginationArray = [];
    for (let i = 0; i < Math.ceil(data.length / size); i++) {
      subarray[i] = data.slice((i * size), (i * size) + size);
      paginationArray.push(i);
    }
    subarray.push(paginationArray);

    return subarray;
  }

  public searchInMarketsList(isChangedInputSearch: boolean): void {
    if (isChangedInputSearch) {
      this.pagination = 0;
    }
    this.marketsList = (this.searchText)
                      ? this.paginationHell(this.homeSearchPipeCalling(this.marketsData, this.searchText))
                      : this.paginationHell(this.marketsData);

  }
  public homeSearchPipeCalling(dataArray: Array<any>, searchText: string): Array<any> {
    return new HomeSearchPipe().transform(this.marketsData, this.searchText);
  }

  public checkForBitcoinName(data: string, pair?: string): string {
    let result = '';
    switch (data.toLowerCase()) {
      case 'bitcoin':
        result = (pair.toUpperCase() === 'USDT-BTC' || pair.toUpperCase() === 'EUR-BTC') ? 'Bitcoin' : 'Bitcoin Cash';
        break;
      default: result = data;
    }
    switch (pair.toUpperCase().split('-')[1]) {
      case 'XRP':
        result = 'XRP';
        break;
      case 'BSV':
        result = ' Bitcoin SV';
        break;
      case 'BAT':
        result = ' Basic Attention Token';
        break;
      case 'DOT':
        result = 'Polkadot';
        break;
      // default: result = data;
    }
    return result;
  }
  public navigateToTrades(pair): void {
    this.dataService.storePair(pair);
    this.router.navigate([`trades/${pair}`]).then();
  }
  public iteration(plus): void {
    plus ? this.pagination++ : this.pagination--;
  }
  public changeSort(): void {
    // if (this.sortParam === 'off')
    //   this.sortParam = 'up';
    // else if (this.sortParam === 'up')
    //   this.sortParam = 'down';
    // else this.sortParam = 'off';
  }
  public toggleSort(param) {
    // console.log('current ' + param, this.sortParams[param]);
    // this.toggleDefaultSort();
    // switch (this.sortParams[param]) {
    //   case 'up':
    //     this.sortParams[param] = 'down';
    //     break;
    //   case 'down':
    //     this.sortParams[param] = 'up';
    //     break;
    //   default: this.sortParams[param] = 'down';
    // }
    // console.log(this.sortParams);
    // console.log('-----');

    if (this.sortParams[param] === 'up') {
      this.sortParams[param] = 'down';
    } else if (this.sortParams[param] === 'down') {
      this.sortParams[param] = 'up';
    } else {
      this.sortParams[param] = 'down';
    }
  }
  public toggleDefaultSort() {
  this.sortParams = {
      sortByVolume: '',
      sortByPair: '',
      sortByCurrency: '',
      sortByChange24h: ''
    };

  }
}
