import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {SvgService} from '../../../services/svg.service';
import {
  OrderbooksHistoryMarketInterface,
  OrderbooksHistoryMarketModel,
  OrderbooksMarketInterface
} from '../../../models/orderbooks-history-market.model';
import {SocketDataClass} from '../../../models/socket-data.class';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {ThemesModel} from '../../../models/themes.model';

@Component({
  selector: 'app-order-book',
  templateUrl: './order-book.component.html',
  styleUrls: ['./order-book.component.scss'],
})
export class OrderBookComponent implements OnInit, OnDestroy {
  @Input() public isMinimized: boolean;
  @Output() public minimized = new EventEmitter<any>();
  public isLightTheme = false;
  public orderBookData: OrderbooksHistoryMarketInterface;
  public orderBookDataSpliced: OrderbooksHistoryMarketInterface = {
    ask: [],
    bid: []
  };
  public orderBookDataThird: OrderbooksHistoryMarketInterface = {
    ask: [],
    bid: []
  };
  public Math: any;
  public isVisibleActualPair = false;
  private destroySubject$: Subject<void> = new Subject();
  public ThemesModel = ThemesModel;

  constructor(
    public dataService: DataService,
    public svgService: SvgService
  ) {
    this.Math = Math;
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnInit() {
    this.initialDataLoading();
    this.dataService.EmitSubscribeToOrderbookUpdates();
    this.dataService.EmitSubscribeToTradeUpdates();
    this.dataService.getMarketPairNewEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.isVisibleActualPair = true;
        this.initialDataLoading();
        setTimeout(() => this.isVisibleActualPair = false, 32000);
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
    this.dataService.EmitUnsubscribeToOrderbookUpdates();
    this.dataService.EmitUnsubscribeToTradeUpdates();

  }

  public initialDataLoading() {
    this.dataService.getOrderBookHistory()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((history: OrderbooksHistoryMarketInterface) => {
        this.orderBookData = history;
        console.log("orderbook history: ", history)

        Object.assign(this.orderBookDataThird, {
          bid: JSON.parse(JSON.stringify(this.orderBookData.bid))
            //  .sort((a, b) => {
            //    if (a.Price < b.Price) { return 1; }
            //    if (a.Price > b.Price) { return -1; }
            //    return 0;
            //  })
        });

        Object.assign(this.orderBookDataThird, {
          ask: JSON.parse(JSON.stringify(this.orderBookData.ask))
            //  .sort((a, b) => {
            //    if (a.Price < b.Price) { return 1; }
            //    if (a.Price > b.Price) { return -1; }
            //    return 0;
            //  })
        });

        Object.assign(this.orderBookDataSpliced, {
          bid: JSON.parse(JSON.stringify(this.orderBookData.bid))
            //  .sort((a, b) => {
            //    if (a.Price < b.Price) { return 1; }
            //    if (a.Price > b.Price) { return -1; }
            //    return 0;
            //  })
             .slice(0, 8)
        });

        Object.assign(this.orderBookDataSpliced, {
          ask: JSON.parse(JSON.stringify(this.orderBookData.ask))
            //  .sort((a, b) => {
            //    if (a.Price < b.Price) { return 1; }
            //    if (a.Price > b.Price) { return -1; }
            //    return 0;
            //  })ß
             .slice(0, 8)
        });

      this.dataService.updateOrderBookData(this.orderBookData);
      this.dataService.SubscribeToOrderbookUpdates()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((socketData: SocketDataClass) => {
          this.orderBookData = this.addToOrderBookNewDates(socketData, this.orderBookData);
          this.orderBookData = this.updateOrderBookDates(socketData, this.orderBookData);
          this.orderBookData = this.deleteEmptyOrderBookDates(this.orderBookData);
          this.orderBookData = this.sortFormatedOrderBookDates(this.orderBookData);
          this.orderBookData = this.formatOrderBookDates(this.orderBookData);
          let arithmeticMeanOrderBookDataAsk =
            this.orderBookData.ask.reduce((a, b) => a + b.Price, 0) /
            this.orderBookData.ask.length;
          let arithmeticMeanOrderBookDataThirdAsk =
            this.orderBookData.ask.reduce((a, b) => a + b.Sum, 0) /
            this.orderBookData.ask.length;

          Object.assign(this.orderBookDataThird, {
            bid: JSON.parse(JSON.stringify(this.orderBookData.bid))
              //  .sort((a, b) => {
              //    if (a.Price < b.Price) { return 1; }
              //    if (a.Price > b.Price) { return -1; }
              //    return 0;
              //  })
               .slice(0, 18)
          });

          Object.assign(this.orderBookDataThird, {
            ask: JSON.parse(JSON.stringify(this.orderBookData.ask))
              //  .sort((a, b) => {
              //    if (a.Price < b.Price) { return 1; }
              //    if (a.Price > b.Price) { return -1; }
              //    return 0;
              //  })
               .slice(0, 17)
          });

          Object.assign(this.orderBookDataSpliced, {
            bid: JSON.parse(JSON.stringify(this.orderBookData.bid))
              //  .sort((a, b) => {
              //    if (a.Price < b.Price) {
              //      return 1;
              //    }
              //    if (a.Price > b.Price) {
              //      return -1;
              //    }
              //    return 0;
              //  })
             .slice(0, 10)
          });

          Object.assign(this.orderBookDataSpliced, {
            ask: JSON.parse(JSON.stringify(this.orderBookData.ask))
              //  .sort((a, b) => {
              //    if (a.Price > b.Price) {
              //      return 1;
              //    }
              //    if (a.Price < b.Price) {
              //      return -1;
              //    }
              //    return 0;
              //  })
             .slice(0, 10)
          });

          this.dataService.updateOrderBookData(this.orderBookData);
      });
    });

/*
    this.dataService.SubscribeToTradeUpdates()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((tradeData) => console.log('trade data', tradeData))
*/
  }

  public checkFoExpNumbers(data: OrderbooksHistoryMarketInterface): any {
    data.ask.forEach(a => {
      if ((('' + a.Price).indexOf('e') >= 0) || (('' + a.Price).indexOf('E') >= 0)) {
        a.Price = this.convertExpToString(a.Price);
      }
    });
    data.bid.forEach(a => {
      if ((('' + a.Price).indexOf('e') >= 0) || (('' + a.Price).indexOf('E') >= 0)) {
        a.Price = this.convertExpToString(a.Price);
      }
    });
    return data;
  }

  public convertExpToString(expNumber: number): string {
      const data = String(expNumber).split(/[eE]/);
      if (data.length === 1) {
        return data[0];
      }
      let z = '';
      const sign = expNumber < 0 ? '-' : '';
      const str = data[0].replace('.', '');
      let mag = Number(data[1]) + 1;

      if (mag < 0) {
        z = sign + '0.';
        while (mag++) {
          z += '0';
        }
        return z + str.replace(/^\-/, '');
      }
      mag -= str.length;
      while (mag--) {
        z += '0';
      }
      return str + z;
  }

  public getTotalWidth(total, key) {
    if (key === 'ask') {
      return ((this.orderBookDataSpliced[key][this.orderBookDataSpliced[key].length - 1].Sum / total)).toFixed(2) + '%' + '';
    };
    return ((total / this.orderBookDataSpliced[key][this.orderBookDataSpliced[key].length - 1].Sum) * 100).toFixed(2) + '%' + '';
  }

  public getTotalWidthThirdTheme(total, key) {
    if (key === 'ask') {
      return ((this.orderBookDataThird[key][this.orderBookDataThird[key].length - 1].Sum / total)).toFixed(2) + '%' + '';
    };
    return ((total / this.orderBookDataThird[key][this.orderBookDataThird[key].length - 1].Sum) * 100).toFixed(2) + '%' + '';
  }

  public addToOrderBookNewDates(socketData: SocketDataClass,
                                marketDateHistory: OrderbooksHistoryMarketInterface): OrderbooksHistoryMarketInterface {
    const buys = socketData.Buys;
    const sells = socketData.Sells;
    const bidsMap = new Map<number, number>(marketDateHistory.bid.map((v) => [+v.Price, +v.Size]));
    let asksMap = new Map<number, number>(marketDateHistory.ask.map((v) => [+v.Price, +v.Size]));
    let bids = [...marketDateHistory.bid];
    let asks = [...marketDateHistory.ask];
/*
    console.log('buys sells', {buys, sells})
*/

    if (buys && buys.length) {
      for (let i = 0; i < buys.length; i++) {
        if (buys[i].Quantity && buys[i].Quantity > 0 && buys[i].Rate && buys[i].Rate > 0) {
          const size = (bidsMap.get(+buys[i].Rate) || 0) + +buys[i].Quantity;
          bidsMap.set(+buys[i].Rate, size);
          asks = asks.filter((v) => v.Price > buys[i].Rate);
/*
          console.log('bid push', {Size: Size, Price: Price, Total: Total, Sum: Sum})
*/
        }
      }
      bids = Array.from(bidsMap.entries()).map(([Price, Size]) => ({Price, Size, Total: Size * Price, Sum: Size}));
      asksMap = new Map(asks.map((v) =>  [+v.Price, +v.Size]));
    }
    if (sells && sells.length) {
      for (let i = 0; i < sells.length; i++) {
        if (sells[i].Quantity && sells[i].Quantity > 0 && sells[i].Rate && sells[i].Rate > 0) {
          const size = (asksMap.get(+sells[i].Rate) || 0) + +sells[i].Quantity;
          asksMap.set(+sells[i].Rate, size);
          bids = bids.filter((v) => v.Price < +sells[i].Rate);
/*
          console.log('ask push', {Size: Size, Price: Price, Total: Total, Sum: Sum})
*/
        }
      }
      asks = Array.from(asksMap.entries()).map(([Price, Size]) => ({Price, Size, Total: Size * Price, Sum: Size}));
    }
/*
    console.log('asks bids', {asks, bids});
*/
    return {...marketDateHistory, ask: asks, bid: bids};
  }

  public updateOrderBookDates(socketData: SocketDataClass,
                              marketDateHistory: OrderbooksHistoryMarketInterface): OrderbooksHistoryMarketInterface {
    const treadingTypeCurrent = Object.keys(socketData).splice(1, 2);
    const treadingTypeHistory = Object.keys(marketDateHistory);

    for (let i = 0; i < treadingTypeCurrent.length; i++) {
      socketData[treadingTypeCurrent[i]].forEach((currentOrderBook) => {

        const index = this.findOrderBook(marketDateHistory[treadingTypeHistory[i]], 'Price', 'Rate', currentOrderBook);

        if (index !== -1) {
          const historyOrderBook = marketDateHistory[treadingTypeHistory[i]][index];
          const updatedOrderBook = this.updateOrderBook(currentOrderBook, historyOrderBook);

          if (updatedOrderBook === null) {
            marketDateHistory[treadingTypeHistory[i]].splice(index, 1);
          } else {
            marketDateHistory[treadingTypeHistory[i]][index] = updatedOrderBook;
          }
        }
        return marketDateHistory;

      });
    }
    return marketDateHistory;
  }

  public updateOrderBook(currentOrderBook, historyOrderBook) {
    // console.log(currentOrderBook);
    switch (currentOrderBook.Type) {
      //ADD QUANTITY
      case 0 : {
        historyOrderBook.Size += currentOrderBook.Quantity;
        return historyOrderBook;
      }
      //REMOVE CURRENT
      case 1: {
        return null;
      }
      //UPDATE QUANTITY
      case 2: {
        return {
          Size: currentOrderBook.Quantity,
          Price: currentOrderBook.Rate
        };
      }
    }
  }

  public sortFormatedOrderBookDates(marketData): OrderbooksHistoryMarketInterface {
    const sortedMarketData: OrderbooksHistoryMarketInterface = new OrderbooksHistoryMarketModel();

    for (const treadingType in marketData) {
      if (marketData.hasOwnProperty(treadingType)) {

        // if (treadingType === 'buy') {
        if (treadingType === 'bid') {
          sortedMarketData[treadingType] = marketData[treadingType].sort((a, b) => {
            if (a.Price > b.Price) {
              return -1;
            }
            if (a.Price < b.Price) {
              return 1;
            }
          });
        }

        // if (treadingType === 'sell') {
        if (treadingType === 'ask') {
          sortedMarketData[treadingType] = marketData[treadingType].sort((a, b) => {
            if (a.Price > b.Price) {
              return 1;
            }
            if (a.Price < b.Price) {
              return -1;
            }
          });
        }
      }
    }
    return sortedMarketData;
  }

  public deleteEmptyOrderBookDates(marketData): OrderbooksHistoryMarketInterface {
    const sortedMarketData: OrderbooksHistoryMarketInterface = new OrderbooksHistoryMarketModel();

    for (const treadingType in marketData) {
      if (marketData.hasOwnProperty(treadingType)) {

        // if (treadingType === 'buy') {
        if (treadingType === 'bid') {
          sortedMarketData[treadingType] = marketData[treadingType].filter((data: OrderbooksMarketInterface) => {
            return data.Price && data.Size && data.Total;
          });
        }

        // if (treadingType === 'sell') {
        if (treadingType === 'ask') {
          sortedMarketData[treadingType] = marketData[treadingType].filter((data: OrderbooksMarketInterface) => {
            return data.Price && data.Size && data.Total;
          });
        }
      }
    }
    return sortedMarketData;
  }

  public formatOrderBookDates(marketData: OrderbooksHistoryMarketInterface): OrderbooksHistoryMarketInterface {
    const newMarketData: OrderbooksHistoryMarketInterface = new OrderbooksHistoryMarketModel();

    for (const treadingType in marketData) {
      if (marketData.hasOwnProperty(treadingType)) {
        newMarketData[treadingType] = marketData[treadingType].reduce((dateAccumulator, date, i) => {
          if (i === 0) {
            dateAccumulator.push({
              Size: date.Size,
              Price: date.Price,
              Total: date.Size * date.Price,
              Sum: date.Size
            });
          } else {
            dateAccumulator.push({
              Size: date.Size,
              Price: date.Price,
              Total: date.Size * date.Price,
              Sum: dateAccumulator[dateAccumulator.length - 1].Sum + date.Size
            });
          }
          return dateAccumulator;
        }, []);
      }
    }
    return newMarketData;
  }

  public findOrderBook(array, fieldOld, fieldNew, value) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][fieldOld] === value[fieldNew]) {
        return i;
      }
    }
    return -1;
  }

  public copyAmountAndPriceToExchange(amount: string, price: string) {
    this.dataService.passExchangeAmount(amount);
    this.dataService.setExchangeAmount(amount);
    this.dataService.passExchangePrice(this.dataService.convertExpToNumber(+price));
    this.dataService.setExchangePrice(this.dataService.convertExpToNumber(+price));
  }
}
