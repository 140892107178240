// src/app/services/cookie.service.ts (adjust the path as necessary)

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  private localPorts = new Set(['4200', '4201', '4202']);
  private isLocalHost = this.localPorts.has(window.location.port);

  constructor() {}

  setCookie(name: string, value: string, days: number) {
    console.log('IS LOCALHOST: ', this.isLocalHost)
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }
    let cookieString = `${name}=${value || ''}${expires}; path=/;`;
    if (!this.isLocalHost) {
      cookieString += ' domain=.biteeu.com;';
    }
    document.cookie = cookieString;
  }

  getCookie(name: string): string | null {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  eraseCookie(name: string) {
    let cookieString = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    if (!this.isLocalHost) {
      cookieString += ' domain=.biteeu.com;';
    }
    document.cookie = cookieString;
  }

}
