import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {DataService} from '../../services/data.service';
import {takeUntil, filter} from 'rxjs/operators';
// import {FeesLimitsModel} from '../../models/fees-and-limits.model';
// import {feesLimits, currencies} from './mock';
import {FeesAndLimitsSearchPipe} from '../../pipes/fees-and-limits/fees-and-limits-search.pipe';

@Component({
  selector: 'app-fees-and-limits',
  templateUrl: './fees-and-limits.component.html',
  styleUrls: ['./fees-and-limits.component.scss']
})

export class FeesAndLimitsComponent implements OnDestroy, OnInit {
  public isLightTheme = false;
  public isPending = true;
  public toggle = false;
  public searchText;
  public sortParams = {
    sortByCurrency: ''
  };
  destroySubject$: Subject<void> = new Subject();

  public feesAndLimits = [];
  public feesAndLimitsImmutable = [];

  constructor(
    public dataService: DataService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
    this.dataService.getAllCurrenciesFees()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.feesAndLimits = data.res;
        this.feesAndLimitsImmutable = data.res;
      });
  }
  public searchInFeesAndLimitsList(): void {
    this.feesAndLimits = this.feesAndLimitsImmutable;
    this.feesAndLimits = (this.searchText)
      ? (this.FeesAndLimitsSearchPipeCalling(this.feesAndLimits, this.searchText))
      : (this.feesAndLimits);
  }
  public FeesAndLimitsSearchPipeCalling(dataArray: Array<any>, searchText: string): Array<any> {
    return new FeesAndLimitsSearchPipe().transform(this.feesAndLimits, this.searchText);
  }
  public toggleSort(param) {
    if (this.sortParams[param] === 'up') {
      this.sortParams[param] = 'down';
    } else if (this.sortParams[param] === 'down') {
      this.sortParams[param] = '';
    } else {
      this.sortParams[param] = 'up';
    }
  }

  ngOnDestroy() {

  }
  ngOnInit() {
    // this.dataService.getMyFeesAndLimits()
    //   .subscribe((data: FeesAndLimitsModel[]) => {
    //       if (data) {
    //           this.feesAndLimits = data.sort((a, b) => b.currency - a.currency);
    //       }
    //     },
    //     (e) => console.error(e));
  }
}
