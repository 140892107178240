import { Component, OnDestroy, OnInit } from '@angular/core';
import { SvgService } from '../../services/svg.service';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { MyProfileInterface } from '../../models/my-profile.model';
import { ThemesModel } from '../../models/themes.model';
import { Subscription } from 'rxjs/internal/Subscription';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-markets',
  templateUrl: './markets.component.html',
  styleUrls: ['./markets.component.scss'],
})
export class MarketsComponent implements OnInit, OnDestroy {
  public hiddenBlocks = {
    chart: false,
    tickers: false,
    balances: false,
    orders: false,
    calculator: false,
    orderHistory: false,
    orderBook: false,
    marketDepth: false,
    trades: false,
    orderMarkets: false,
  };
  public userId: number;
  public isGeoBlockedCountry = false;
  public isGeoBlockedCity = false;
  public ThemesModel = ThemesModel;
  destroySubject$: Subject<void> = new Subject();
  private currentThemeSubscription: Subscription;
  public isBcxg: boolean;

  constructor(
    public svgService: SvgService,
    public authService: AuthService,
    public dataService: DataService,
    public route: ActivatedRoute,
  ) {
    route.url.subscribe(url => {
      this.dataService.market = url[url?.length - 1]?.path || 'BTC-ETH';
    });

  }

  ngOnInit() {
    // setTimeout(() => this.dataService.findUserCountry(), 1000);
    this.dataService.joinRoom();

    const pair = this.dataService.getPair();
    // this.isBcxg =
    // (pair.includes('USDT') && pair.includes('BCXG')) ||
    // (pair.includes('USDT') && pair.includes('DOT')) ||
    // (pair.includes('USDT') && pair.includes('XRP')) ||
    // (pair.includes('BTC') && pair.includes('XRP')) ||
    // (pair.includes('BTC') && pair.includes('BCXG')) ||
    // (pair.includes('BTC') && pair.includes('LTC')) ||
    // (pair.includes('ETH') && pair.includes('LTC'))

    if (this.authService.isLoggedIn) {
      this.getUserId();
      this.dataService.getUserProfile()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((res: MyProfileInterface) => {
          this.dataService.passResidenceCountry(+res.residence_country_id);
          this.dataService.setResidenceCountry(+res.residence_country_id);
          this.dataService.passCitizenshipCountry(+res.country_id);
          this.dataService.setCitizenshipCountry(+res.country_id);
          this.dataService.passUserId(res.id);
          this.dataService.setUserId(res.id);
          this.dataService.passUsersProfile(res);
          this.dataService.setUsersProfile(res);
          if (res.cookies_agreed === 0 || res.cookies_agreed === 1) {
            this.dataService.passUserIsCokiesAgreed(res.cookies_agreed);
            this.dataService.setUserIsCokiesAgreed(res.cookies_agreed);
          }
        });

      if (this.userId) {
        this.dataService.joinRoomUserId('' + this.userId);
      } else {
        this.dataService.getUserProfileFromServer();
        this.dataService.getUserIdEmitter()
          .pipe(takeUntil(this.destroySubject$))
          .subscribe((response: number) => {
            this.userId = response;
            this.dataService.joinRoomUserId('' + this.userId);
          });
      }
      this.checkGeoForBlackListedCountry();
      this.checkIfResidenceCountryIsInBlackListAndIfCitizenshipIsInBlackList();
    } else {
      if (!localStorage.getItem('isCookiesAgree') || +localStorage.getItem('isCookiesAgree') === 0) {
        this.dataService.passUserIsCokiesAgreed(0);
        this.dataService.setUserIsCokiesAgreed(0);
      }
    }
    this.dataService.getMarketMinOrderAmount();
    this.currentThemeSubscription = this.dataService.currentThemeSubject.subscribe(res => {
      this.dataService.setCurrentTheme(res);
    });
  }

  ngOnDestroy() {
    this.dataService.leaveRoom();
    this.dataService.leaveRoomUserId('' + this.userId);
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
    this.dataService.passExchangeAmount(undefined);
    this.dataService.setExchangeAmount(undefined);
    this.dataService.passExchangePrice(undefined);
    this.dataService.setExchangePrice(undefined);
    this.currentThemeSubscription.unsubscribe();
  }

  public hideToggle(block) {
    this.hiddenBlocks[block] = !this.hiddenBlocks[block];
  }

  public getUserId() {
    if (this.authService.isLoggedIn) {
      this.userId = this.dataService.getUserId() ? this.dataService.getUserId() : +localStorage.getItem('visible-set');
      if (!this.userId) {
        this.dataService.getUserProfileFromServer();
      }
      this.dataService.getUserIdEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((response: number) => {
          this.userId = response;
        });
    }
  }

  public blockUserActivity() {
    // this.router.navigate(['/login']);
    // this.dataService.setLoginIncomeErrorStatus(['isGeoBlockedCountry']);
    // this.dataService.passLoginIncomeErrorStatus(['isGeoBlockedCountry']);
    // this.authService.logout();
  }

  public checkIfResidenceCountryIsInBlackListAndIfCitizenshipIsInBlackList() {
    let isResidenceCountryInBlackList = this.dataService.doVerifyIfResidenceCountryIsInBlackList(this.dataService.getResidenceCountry());
    let isCitizenshipInBlackList = this.dataService.doVerifyIfCitizenshipIsInBlackList(this.dataService.getCitizenshipCountry());
    // console.log('isCitizenshipInBlackList', isCitizenshipInBlackList);
    // console.log('isResidenceCountryInBlackList 2', isResidenceCountryInBlackList);
    if (isResidenceCountryInBlackList || isCitizenshipInBlackList) {
      this.blockUserActivity();
    }
    this.dataService.getResidenceCountryEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(residenceCountry => {
        isResidenceCountryInBlackList = this.dataService.doVerifyIfResidenceCountryIsInBlackList(residenceCountry);
        // console.log('isResidenceCountryInBlackList 2', isResidenceCountryInBlackList);
        if (isResidenceCountryInBlackList) {
          this.blockUserActivity();
        }
      });
    this.dataService.getCitizenshipCountryEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(residenceCountry => {
        isCitizenshipInBlackList = this.dataService.doVerifyIfCitizenshipIsInBlackList(residenceCountry);
        // console.log('isCitizenshipInBlackList', isCitizenshipInBlackList);
        if (isCitizenshipInBlackList) {
          this.blockUserActivity();
        }
      });
  }

  public checkGeoForBlackListedCountry() {
    // const isResidenceCountryInBlackList = this.dataService.doVerifyIfResidenceCountryIsInBlackList(+res.residence_country_id);
    // const isCitizenshipInBlackList = this.dataService.doVerifyIfCitizenshipIsInBlackList(+res.country_id);
    // start GEO blocking
    let isGeoCountryIsInBlackList: boolean;
    let isGeoCountryIsInRestricktedList: boolean;
    let userIsoCountry = this.dataService.getUserIsoCountry();

    if (userIsoCountry && userIsoCountry.length) {
      this.dataService.doVerifyIfGeoCountryIsInBlackListAndInRestricktedList(userIsoCountry);
      isGeoCountryIsInBlackList = this.dataService.getIsGeoCountryIsInBlackList();
      isGeoCountryIsInRestricktedList = this.dataService.getIsGeoCountryIsInRestricktedList();
      if (isGeoCountryIsInBlackList) {
        this.isGeoBlockedCountry = true;
        this.blockUserActivity();
      }
    } else {
      this.dataService.getUserIsoCountryEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response1 => {
          userIsoCountry = response1;
          if (userIsoCountry && userIsoCountry.length) {
            this.dataService.doVerifyIfGeoCountryIsInBlackListAndInRestricktedList(userIsoCountry);
            isGeoCountryIsInBlackList = this.dataService.getIsGeoCountryIsInBlackList();
            isGeoCountryIsInRestricktedList = this.dataService.getIsGeoCountryIsInRestricktedList();
            if (isGeoCountryIsInBlackList) {
              this.isGeoBlockedCountry = true;
              this.blockUserActivity();
            }
          }
        });
      this.dataService.getIsGeoCountryIsInBlackListEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response2 => {
          isGeoCountryIsInBlackList = response2;
          // console.log('isGeoCountryIsInBlackList', isGeoCountryIsInBlackList);
          if (isGeoCountryIsInBlackList) {
            this.isGeoBlockedCountry = true;
            this.blockUserActivity();
          }
        });
      this.dataService.getIsGeoCountryIsInRestricktedListEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response2 => {
          isGeoCountryIsInRestricktedList = response2;
          // console.log('isGeoCountryIsInRestricktedList', isGeoCountryIsInRestricktedList);
        });
    }

    // city GEO blocking
    let userCity = this.dataService.getUserCity();
    if (userCity && userCity.length) {
      const isGeoCityIsInBlackList = this.dataService.doVerifyIfGeoCityIsInBlackList(userCity);
      if (isGeoCityIsInBlackList) {
        this.isGeoBlockedCity = true;
        this.blockUserActivity();
      }
    } else {
      this.dataService.getUserCityEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response => {
          userCity = response;
          if (userCity && userCity.length) {
            const isGeoCityIsInBlackList = this.dataService.doVerifyIfGeoCityIsInBlackList(userCity);
            if (isGeoCityIsInBlackList) {
              this.isGeoBlockedCity = true;
              this.blockUserActivity();
            }
          }
        });
    }
    // end GEO blocking
  }

  // private getMarketMinOrderAmount() {
  //   this.dataService.getMarketPairsShortInfoServer()
  //     .pipe(takeUntil(this.destroySubject$))
  //     .subscribe(data => {
  //       const marketPairMinOrderAmount = data[this.dataService.getPair()];
  //       this.dataService.passMarketPairMinOrderAmount(marketPairMinOrderAmount);
  //       this.dataService.setMarketPairMinOrderAmount(marketPairMinOrderAmount);
  //     });
  // }

}
