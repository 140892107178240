import { Injectable, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService implements OnDestroy {
  private routerSubscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private meta: Meta
  ) {}

  public init(): void {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ url }: NavigationEnd) => {
        let title: string;
        let desc: string;
        if (url === '/sign-up') {
          title = 'Sign up to Biteeu';
          desc = 'Sign up with Email address and Password'
        } else if (url === '/login') {
          title = 'Login to Biteeu';
          desc = 'Log In. Email address: Password:'
        } else if (url === '/markets') {
          title = 'Bitcoin markets';
          desc = 'Choose BTC, ETH, USDT trade pair'
        } else {
          title = 'Biteeu';
        }
        this.setAppTitle(title);
        this.updateDescription(desc);
      });
  }

  private setAppTitle(title: string): void {
      this.titleService.setTitle(title);
  }

  private updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
