import {Component, OnDestroy, OnInit} from '@angular/core';
import {ViewStateService} from '../../services/view-state.service';

@Component({
  selector: 'app-service-unavaliable',
  templateUrl: './service-unavaliable.component.html',
  styleUrls: ['./service-unavaliable.component.css']
})
export class ServiceUnavaliableComponent implements OnInit, OnDestroy {

  constructor(private _viewState: ViewStateService) { }

  ngOnInit(): void {
    this._viewState.displayHeader$.next(false);
  }

  ngOnDestroy(): void {
    this._viewState.displayHeader$.next(true);
  }

}
