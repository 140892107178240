import {Component, OnDestroy, OnInit} from '@angular/core';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {RegistrationStatusesClass} from '../../models/registration-statuses.class';
import {DepositWithdrawalInterface} from '../../models/deposit-withdrawal.model';
import {MarketLastPriceInterface} from '../../models/market-last-price.model';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {MatDialog} from '@angular/material/dialog';
import {NotVerifiedComponent} from '../../dialogs/not-verified/not-verified.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  public marketsListAll = [];
  public marketsListFav = [];
  public marketsListBTC = [];
  public marketsListETH = [];
  public marketsListUSDT = [];
  public marketsListEUR = [];
  public marketListSocket = [];
  private isFiltering: boolean;
  // public totalDepositUSDT = 0;
  public totalDepositsLimit1USDT = 50;
  public kycStatus: number;
  public notVerifiedOpen = false;

  public userId: number;
  public isTestAccount = false;
  destroySubject$: Subject<void> = new Subject();

  constructor(public dataService: DataService,
              public authService: AuthService,
              private router: Router,
              public dialog: MatDialog) {}

  ngOnInit(): void {
    this.isTestAccount = [ 370, 1306 ].some(x => x == this.dataService.getUserId());
    this.getMarketsList();
    if (this.authService.isLoggedIn) {
      this.getUserIId();
      if (this.userId) {
        this.dataService.joinRoomUserId('' + this.userId);
        this.checkFreeCoinsStatus(this.userId);
      }
      this.dataService.getUserIdEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((response: number) => {
          this.userId = response;
          this.dataService.joinRoomUserId('' + this.userId);
          this.checkFreeCoinsStatus(this.userId);
        });
      this.dataService.SubscribeToUpdateFavs()
        .subscribe((data: any) => {
          if (data.u) {
            this.getMarketsList();
          }
        });

      this.getKycStatus();
    } else {
      if (!localStorage.getItem('isCookiesAgree') || +localStorage.getItem('isCookiesAgree') === 0) {
        this.dataService.passUserIsCokiesAgreed(0);
        this.dataService.setUserIsCokiesAgreed(0);
      }
    }

  }

  ngOnDestroy() {
    this.dataService.leaveRoomUserId('' + this.userId);
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public getKycStatus() {
    this.dataService.getUserKycStatus()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: any) => {
        this.kycStatus = res.status;
      }, () => {
        // console.log(error1);
      });
  }

  public openNotVerified() {
    this.dialog.open(NotVerifiedComponent).afterClosed()
      .subscribe(() => {
        this.router.navigateByUrl('user-settings/identity-verification').then();
      });
    this.notVerifiedOpen = true;
  }

  public checkFreeCoinsStatus(userId: number) {
    this.dataService.getFreeCoinsStatus(userId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((result: RegistrationStatusesClass) => {

          if (result.registrationBonus === 0) {
            // this.dataService.getBalances()
            //   .pipe(takeUntil(this.destroySubject$))
            //   .subscribe((wallets: Array<WalletBalanceInterface>) => {
            //     const currencyForBonuses = 'SPC';
            //     // const currencyForBonuses = 'ADA';
            //
            //     const walletForBonuses = wallets.filter(wallet => {
            //       return (wallet.currency === currencyForBonuses);
            //     });
            //     if (walletForBonuses && walletForBonuses.length
            //       && walletForBonuses[0].id && (walletForBonuses[0].id.length > 1)) {
            //       try {
            //         this.addRegistrationBonus(userId);
            //       }  catch (e) {
            //       }
            //     } else {
            //       this.dataService.passMessageTypeForAlertModal(2);
            //       this.dataService.setMessageTypeForAlertModal(2);
            //     }
            //   });
          }

          if (result.depositeBonus === 0) {
            let totalDepositUSDT: number;
            // totalDepositUSDT = this.dataService.getTotalDepositUSDT();
            // if (totalDepositUSDT && ((totalDepositUSDT) > this.totalDepositsLimit1USDT)) {
            //   this.addDepositBonus(userId);
            // }
            this.dataService.getTotalDepositUSDTEmitter()
              .pipe(takeUntil(this.destroySubject$))
              .subscribe(data => {
                totalDepositUSDT = data;
                if (totalDepositUSDT && ((totalDepositUSDT) > this.totalDepositsLimit1USDT)) {
                  try {
                    this.addDepositBonus(userId);
                  } catch (e) {}
                }
              });
          }

          if (result.KycVerificationBonus === 0) {
            this.dataService.getUserKycStatus()
              .pipe(takeUntil(this.destroySubject$))
              .subscribe((res: any) => {
                if (res.status && (res.status === 1)) {
                  try {
                    this.addKycBonus(userId);
                  } catch (e) {}
                }
              });
          }

          this.getTransactions();
      }, error => {
        return observableThrowError(error);
      });
  }

  public addRegistrationBonus(userId: number) {
        this.dataService.postRegistrationBonus(userId)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(() => {
            this.dataService.passMessageTypeForAlertModal(1);
            this.dataService.setMessageTypeForAlertModal(1);
          });
  }

  public addDepositBonus(userId: number) {
        this.dataService.postDepositBonus(userId)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(() => {
            this.dataService.passMessageTypeForAlertModal(3);
            this.dataService.setMessageTypeForAlertModal(3);
          });
  }

  public addKycBonus(userId: number) {
        this.dataService.postKycBonus(userId)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(() => {
            this.dataService.passMessageTypeForAlertModal(4);
            this.dataService.setMessageTypeForAlertModal(4);
          });
  }

  private getTransactions(): void {
    this.dataService.getDepositsWithdrawals()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        const deposits = [];
        const withdrawals = [];
        let history: Array<any>;
        if (data.deposits && (<Array<DepositWithdrawalInterface>>data.deposits).length) {
          const ds = data.deposits;
          for (let i = 0; i < ds.length; i++) {
            deposits.push(Object.assign({}, ds[i], {'type': 'Deposit'}));
          }
        }
        if (data.withdrawals && (<Array<DepositWithdrawalInterface>>data.withdrawals).length) {
          const ws = data.withdrawals;
          for (let i = 0; i < ws.length; i++) {
            withdrawals.push(Object.assign({}, ws[i], {'type': 'Withdrawal'}));
          }
        }
        history = withdrawals
          .concat(deposits)
          .sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at));
        // const withdrawsHistory = this.getTransactionsHistoryByType(history, 'Withdrawal');
        this.countTotalDeposits(history);
      });
  }
  private countTotalDeposits(_history: Array<DepositWithdrawalInterface>): void {
      let totalDepositUSDT = 0;
      this.dataService.getTicksHistory()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((data: Array<any>) => {
          totalDepositUSDT = 0;
          const currencyPricesBTC = data.filter(item => item.name === 'BTC')[0];
          const BTCtoUSDTlastPrice = ( currencyPricesBTC
            && (<MarketLastPriceInterface>currencyPricesBTC.USDT)
            && (<MarketLastPriceInterface>currencyPricesBTC.USDT).Last )
            ? (<MarketLastPriceInterface>currencyPricesBTC.USDT).Last : 0;
          const deposits = this.getTransactionsHistoryByType(_history, 'Deposit');
          deposits.forEach((transaction: DepositWithdrawalInterface) => {
            if (transaction.currency !== 'USDT') {
              const currencyPrices = data.filter(item => {
                return item.name === transaction.currency;
              })[0];
              const currencyPricesUSDT = (
                (<MarketLastPriceInterface>currencyPrices
                  && (<MarketLastPriceInterface>currencyPrices.USDT)
                  && (<MarketLastPriceInterface>currencyPrices.USDT).Last)
                  ? +(<MarketLastPriceInterface>currencyPrices.USDT).Last
                  : ( (<MarketLastPriceInterface>currencyPrices
                    && (<MarketLastPriceInterface>currencyPrices.BTC)
                    && (<MarketLastPriceInterface>currencyPrices.BTC).Last)
                    ? (<MarketLastPriceInterface>currencyPrices.BTC).Last * BTCtoUSDTlastPrice : 0 )
              );
              totalDepositUSDT += currencyPricesUSDT * (+transaction.quantity);
            } else {
              totalDepositUSDT += +transaction.quantity;
            }
          });
          this.dataService.passTotalDepositUSDT(totalDepositUSDT);
          this.dataService.setTotalDepositUSDT(totalDepositUSDT);
        }, error => {
          return observableThrowError(error);
        });
  }
  public getTransactionsHistoryByType(history: Array<DepositWithdrawalInterface>, type?: any): Array<DepositWithdrawalInterface> {
    if (type) {
      return history.filter(item => {
        return item.type.toLowerCase() === type.toLowerCase();
      });
    } else {
      return history;
    }
  }


  public getMarketsList() {
    this.dataService.marketsDetails$
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(marketsList => {
        this.marketListPreparation(marketsList);
      });
  }

  public getUserIId() {
    this.userId = this.dataService.getUserId() ? this.dataService.getUserId() : +localStorage.getItem('visible-set');
    if (!this.userId) {
      this.dataService.getUserProfileFromServer();
    }
    this.dataService.getUserIdEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((response: number) => {
        this.userId = response;
      });
  }

  public marketListPreparation(data): void {
    console.log(data);
    this.marketsListAll = data;
    if (this.authService.isLoggedIn) {
      this.filterFavorites();
    }
    this.marketsListBTC = this.filterMarket('BTC').sort((a:any, b:any) => +b.BaseVolume24 - +a.BaseVolume24);
    this.marketsListETH = this.filterMarket('ETH').sort((a:any, b:any) => +b.BaseVolume24 - +a.BaseVolume24);
    this.marketsListUSDT = this.filterMarket('USDT').sort((a:any, b:any) => +b.BaseVolume24 - +a.BaseVolume24);
    this.marketsListEUR = this.filterMarket('EUR').sort((a:any, b:any) => +b.BaseVolume24 - +a.BaseVolume24);
  }
  private filterFavorites(): any {
    if (!this.isFiltering) {
      this.isFiltering = true;
      this.dataService.getFavoriteMarkets()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
        this.marketsListFav = this.marketsListAll.filter(v => res.includes(v.MarketName));
        this.isFiltering = false;
      });
    }
  }

  private filterMarket(curr): any {
    return this.marketsListAll.filter(v => v.MarketName.split('-')[0] === curr);
  }

}
