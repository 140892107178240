import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {MyProfileInterface} from '../../../../models/my-profile.model';
import snsWebSdk from '@sumsub/websdk';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss']
})
export class GeneralInfoComponent implements OnInit, OnDestroy {
  public isLightTheme = false;
  public isResidenceCountryInBlackList = true;
  public isCitizenshipInBlackList = true;
  public isSpinnedStatus = true;
  public isUserCompletedProfileForm = false;
  public kycStatus: number;
  public isUploadedImages: number;
  public userEmail: string;
  destroySubject$: Subject<void> = new Subject();

  @Input() lang: string;

  @Output()
  public onStartClicked = new EventEmitter(false);

  constructor(public dataService: DataService) {
    // console.log('isResidenceCountryInBlackList 1', this.isResidenceCountryInBlackList);
    this.isResidenceCountryInBlackList = this.dataService.doVerifyIfResidenceCountryIsInBlackList(this.dataService.getResidenceCountry());
    this.isCitizenshipInBlackList = this.dataService.doVerifyIfCitizenshipIsInBlackList(this.dataService.getCitizenshipCountry());
    // console.log('isCitizenshipInBlackList', this.isCitizenshipInBlackList);
    // console.log('isResidenceCountryInBlackList 2', this.isResidenceCountryInBlackList);
    this.dataService.getResidenceCountryEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(residenceCountry => {
        this.isResidenceCountryInBlackList = this.dataService.doVerifyIfResidenceCountryIsInBlackList(residenceCountry);
      });
    this.dataService.getCitizenshipCountryEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(residenceCountry => {
        this.isCitizenshipInBlackList = this.dataService.doVerifyIfCitizenshipIsInBlackList(residenceCountry);
        // console.log('isCitizenshipInBlackList', this.isCitizenshipInBlackList);
      });

    this.loadIsUserCompletedProfileFormData();
    // this.loadIsResidenceCountryInBlackListData();
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;

      });
  }

  ngOnInit(): void {
    if (!this.dataService.getUsersProfile()) {
      this.dataService.getUserProfileFromServer();
    }

    const profile = this.dataService.getUsersProfile();
    if (profile && profile.id) {
      this.dataService.isUserCompletedProfileFormGetter(profile);
    }
    this.dataService.getUsersProfileEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((response: MyProfileInterface) => {
        if (response && response.id) {
          this.dataService.isUserCompletedProfileFormGetter(response);
        }
      });

    this.getKycStatus();

    this.dataService.getUserProfile()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((profileResult: MyProfileInterface) => {
        this.userEmail = profileResult.email.split('@')[0];
        this.dataService.getSumsubAccessToken(this.userEmail, 1200)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe((sumsubResult) => {
              const apiUrl = 'https://api.sumsub.com';
              const flowName = 'basic-kyc';
              const { token } = sumsubResult;
              const applicantEmail = '';
              const applicantPhone = '';
              this.launchWebSdk(token, applicantEmail, applicantPhone);
            });
          });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public loadIsUserCompletedProfileFormData() {
    this.isUserCompletedProfileForm = this.dataService.getIsUserCompletedProfileForm();
    // console.log('isUserCompletedProfileForm 1', this.isUserCompletedProfileForm);
    if (!this.isUserCompletedProfileForm) {
      this.dataService.passIsVisibleProfileEmptyFields(true);
      this.dataService.setIsVisibleProfileEmptyFields(true);
    }
    this.dataService.getIsUserCompletedProfileFormEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isUserCompletedProfileForm = data;
        // console.log('isUserCompletedProfileForm 2', this.isUserCompletedProfileForm);
        if (!this.isUserCompletedProfileForm) {
          this.dataService.passIsVisibleProfileEmptyFields(true);
          this.dataService.setIsVisibleProfileEmptyFields(true);
        }
      });
  }

  public getKycStatus() {
    this.dataService.getUserKycStatus()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: any) => {
        // console.log(res);
        this.isUploadedImages = res.isUploaded;
        this.kycStatus = res.status;
        this.isSpinnedStatus = false;
      }, () => {
        // console.log(error1);
        this.isSpinnedStatus = false;
      });
  }

  public submitStartVerification() {
    if (!this.isResidenceCountryInBlackList && !this.isCitizenshipInBlackList
      && this.isUserCompletedProfileForm
      && this.kycStatus === 0 && !this.isUploadedImages) {
      this.onStartClicked.emit(true);
    }
  }

  // public loadIsResidenceCountryInBlackListData() {
  //   this.isResidenceCountryInBlackList = this.dataService.getIsResidenceCountryInBlackList();
  //   // console.log('isResidenceCountryInBlackList 3', this.isResidenceCountryInBlackList);
  //   this.dataService.getIsResidenceCountryInBlackListEmitter()
  //     .pipe(takeUntil(this.destroySubject$))
  //     .subscribe(data => {
  //       this.isResidenceCountryInBlackList = data;
  //       // console.log('isResidenceCountryInBlackList 4', this.isResidenceCountryInBlackList);
  //     });
  // }
//TODO Return this if new not working
/*  public launchWebSdk(apiUrl, flowName, accessToken, applicantEmail, applicantPhone) {
    let snsWebSdkInstance = snsWebSdk.Builder(apiUrl, flowName)
        .withAccessToken(
            accessToken,
            (newAccessTokenCallback) => {
                // Access token expired
                // get a new one and pass it to the callback to re-initiate the WebSDK
                let newAccessToken = '...'; // get a new token from your backend
                newAccessTokenCallback(newAccessToken)
            }
        )
        .withConf({
            lang: 'en',
            email: applicantEmail,
            phone: applicantPhone,
            onMessage: (type, payload) => {
                // see below what kind of messages the WebSDK generates
                // console.log('WebSDK onMessage', type, payload)
            },
            onError: (error) => {
                // console.error('WebSDK onError', error)
            },
        })
        .build();

    // you are ready to go:
    // just launch the WebSDK by providing the container element for it
    snsWebSdkInstance.launch('#sumsub-websdk-container')
  }*/

  public launchWebSdk(accessToken, applicantEmail, applicantPhone) {
    let snsWebSdkInstance = snsWebSdk.init(
      accessToken,
      () => this.getNewAccessToken()
    ).withConf({
      lang: this.lang || 'en',
      email: applicantEmail,
      phone: applicantPhone,
    })// see below what kind of messages WebSDK generates
      .on('idCheck.stepCompleted', (payload) => {
        console.log('stepCompleted', payload);
      })
      .on('idCheck.onError', (error) => {
        console.log('onError', error);
      }).build();

    // you are ready to go:
    // just launch the WebSDK by providing the container element for it
    snsWebSdkInstance.launch('#sumsub-websdk-container');
  }

 async getNewAccessToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataService.getSumsubAccessToken(this.userEmail, 1200).subscribe(data => {
        resolve(data);
      }, error => reject(error));
    });
  }
}
