import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../../services/data.service';
import {MyProfileInterface} from '../../../models/my-profile.model';
import {MOBILE_VALIDATION} from '../../../app.constants';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {ValidationService} from '../../../services/validation.service';
import {TickHistoryModel} from '../../../models/tick-history.model';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit, OnDestroy {
  public isLightTheme = false;
  public userData: MyProfileInterface;
  public profileForm: FormGroup;
  public _profileForm = {};
  public initialForm: any = {};
  public countries: Array<object>;
  public blackListCountries: Array<number>;
  public isResidenceCountryInBlackList = undefined;
  public isCitizenshipInBlackList = undefined;
  public isReadOnlyStreetAddress = false;
  public isReadOnlyAptOrUnit = false;
  public isReadOnlyBirthDay = false;
  public isReadOnlyBirthMonth = false;
  public isReadOnlyBirthYear = false;
  public isReadOnlyCity = false;
  public isReadOnlyCitizenship = false; //Country
  public isReadOnlyFirstName = false;
  public isReadOnlyLastName = false;
  public isReadOnlyState = false;
  public isReadOnlyResidenceCountry = false;
  public isReadOnlyZip = false;
  public isVisibleSubmitButton = false;
  public isVisibleActivity = true;
  public isGeoBlockedCountry = false;
  public isVisibleEmptyFields = false;
  public arrayNotCompletedProfileFields: Array<string>;

  public months: Array<object>;
  public keysProfileForm = [];
  public email: string;
  public days;
  public years;
  public successMessage = '';
  public defaultMessage = '';
  public isPending = false;
  public reCaptcha_PROD_is_visible = false;
  public errors = {
    firstNameIsNotInEnglish: false,
    lastNameIsNotInEnglish: false,
    cityIsNotInEnglish: false,
    streetIsNotInEnglish: false,
    aptOrUnitIsNotInEnglish: false,
    stateOrProvinceIsNotInEnglish: false,
    postalOrZipcodeIsNotInEnglish: false,
    firstNameIsEmpty: false,
    lastNameIsEmpty: false,
    cityIsEmpty: false,
    streetIsEmpty: false,
    aptOrUnitIsEmpty: false,
    stateOrProvinceIsEmpty: false,
    postalOrZipcodeIsEmpty: false,
    birthMonthIsEmpty: false,
    birthDayIsEmpty: false,
    birthYearIsEmpty: false,
    citizenShipIsEmpty: false,
    residenceCountryIsEmpty: false,
  };
  public isReferralCode = false;

  destroySubject$: Subject<void> = new Subject();

  constructor(private dataService: DataService,
              public authService: AuthService,
              private fb: FormBuilder,
              private router: Router,
              public validationService: ValidationService) {
    this.prepareProfileForm();
    this.dataService.getIsResidenceCountryInBlackListEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isInBlackList => {
        // this.isResidenceCountryInBlackList = isInBlackList;
      });
    this.dataService.getIsCitizenshipInBlackListEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isInBlackList => {
        // this.isCitizenshipInBlackList = isInBlackList;
      });
    this.createForm();
    this.setDaysAndMonths();
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  public ngOnInit(): void {
    if (!(this.dataService.getCountries() && this.dataService.getCountries().length)) {
      this.dataService.getCountriesData();
      this.dataService.getCountriesEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response => {
          this.countries = this.changeCountriesNameView(response);
          // console.log('after changeCountriesNameView 1', this.countries);
        });
    } else {
      this.countries = this.changeCountriesNameView(this.dataService.getCountries());
      // console.log('after changeCountriesNameView 2', this.countries);
    }
    if (!(this.dataService.getBlackListCountriesIndexes() && this.dataService.getBlackListCountriesIndexes().length)) {
      this.dataService.getBlackListCountriesData();
      this.dataService.getBlackListCountriesIndexesEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response => {
          this.blackListCountries = response;
        });
    } else {
      this.blackListCountries = this.dataService.getBlackListCountriesIndexes();
    }
    // setTimeout(() => console.log('BlackListCountries', this.dataService.getBlackListCountriesIndexes()), 5000);
    // this.getIsVisibleProfileEmptyFields();
    this.getArrayNotCompletedFields();
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public prepareProfileForm() {
    if (this.authService.isLoggedIn) {
      this.isPending = true;
      this.dataService.getUserProfile()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((res: MyProfileInterface) => {
          // console.log('getUserProfile', res);
          const separator = this.reCaptcha_PROD_is_visible ? '/' : '-';
          this.userData = res;
          this.email = res.email;
          const birthDay = res.birthday ? res.birthday.split(separator) : new Array(3);
          // console.log(birthDay);
          this.userData.birthYear = this.reCaptcha_PROD_is_visible
            ? (birthDay[2] ? birthDay[2] : undefined)
            : (birthDay[0] ? birthDay[0] : undefined);
          this.userData.birthMonth = this.reCaptcha_PROD_is_visible
            ? (birthDay[0] ? birthDay[0] : undefined)
            : (birthDay[1] ? birthDay[1] : undefined);
          this.userData.birthDay =  this.reCaptcha_PROD_is_visible
            ? ((birthDay[1] && birthDay[1].slice(0, 2)) ? birthDay[1].slice(0, 2) : undefined)
            : ((birthDay[2] && birthDay[2].slice(0, 2)) ? birthDay[2].slice(0, 2) : undefined);
          if (this.userData.birthMonth && this.userData.birthMonth.length === 1) {
            this.userData.birthMonth = `0${this.userData.birthMonth}`;
          }
          if (this.userData.birthDay && this.userData.birthDay.length === 1) {
            this.userData.birthDay = `0${this.userData.birthDay}`;
          }
          this.createFormWithData(this.userData);
          this.dataService.passResidenceCountry(+res.residence_country_id);
          this.dataService.setResidenceCountry(+res.residence_country_id);
          this.dataService.passCitizenshipCountry(+res.country_id);
          this.dataService.setCitizenshipCountry(+res.country_id);
          this.dataService.passUsersProfile(res);
          this.dataService.setUsersProfile(res);
          this.dataService.isUserCompletedProfileFormGetter(res);

          // this.isResidenceCountryInBlackList = this.dataService.doVerifyIfResidenceCountryIsInBlackList(+this.residenceCountryIdValue);
          // this.isCitizenshipInBlackList = this.dataService.doVerifyIfCitizenshipIsInBlackList(+this.citizenshipIdValue);
          setTimeout(() => this.checkReadOnlyFields(this.userData), 100);
          this.isPending = false;
          if (res.cookies_agreed === 0 || res.cookies_agreed === 1) {
            this.dataService.passUserIsCokiesAgreed(res.cookies_agreed);
            this.dataService.setUserIsCokiesAgreed(res.cookies_agreed);
          }
        });
    }
  }

  public changeCountriesNameView(_countries: Array<object>): Array<object> {
    const countries = _countries;
    let index;
    countries.forEach((country: any, i) => {
      if (country.name === 'RWANDA') {country.name = 'Rwanda'; }
      if (country.name === 'Myanmar') {country.name = 'Myanmar (Burma)'; }
      if (country.name === 'Korea, Democratic People\'S Republic of') {country.name = 'Korea, Democratic People\'s Republic of'; }
      if (country.name === 'AndorrA') {country.name = 'Andorra'; }
      if (country.name === 'Brunei Darussalam') {country.name = 'Brunei'; }
      if (country.name === 'Congo, The Democratic Republic of the') {
        index = i;
        // console.log('Congo, The Democratic Republic of the', country.id);
      }
      if (country.name === 'Cote D\'Ivoire') {country.name = 'Cote d\'Ivoire'; }
      if (country.name === 'Heard Island and Mcdonald Islands') {country.name = 'Heard Island and McDonald Islands'; }
      if (country.name === 'Lao People\'S Democratic Republic') {country.name = 'Lao People\'s Democratic Republic'; }
      if (country.name === 'Libyan Arab Jamahiriya') {country.name = 'Libya'; }
    });

    if (index) {
      countries.splice(index, 1);
    }
    return countries.sort((a, b) => {
      if ((<any>a).name > (<any>b).name) {return 1; }
      if ((<any>a).name < (<any>b).name) {return -1; }
    });
  }

  public updateProfile(): void {
    // console.log('updateProfile is running...');
    // console.log(this.profileForm);
    this.dataService.passIsVisibleProfileEmptyFields(true);
    this.dataService.setIsVisibleProfileEmptyFields(true);
    if (this.profileForm.valid) {
    this.isPending = true;
    this.resetErrors();
      const data = this.profileForm.value;
      // const birthday = this.reCaptcha_PROD_is_visible
      //   ? (`${data.birthYear ? '' + data.birthYear : '2000'}-${data.birthMonth ? data.birthMonth : '01'}
      //   -${data.birthDay ? data.birthDay : '01'}`)
      //   : (`${data.birthYear ? '' + data.birthYear : '2000'}-${data.birthMonth ? data.birthMonth : '01'}
      //   -${data.birthDay ? data.birthDay : '01'}`);
      const birthday = `${this.isReadOnlyBirthYear ? this.initialForm['birthYear'] : (data.birthYear ? '' + data.birthYear : '2000')}`
                      + `-${this.isReadOnlyBirthMonth ? this.initialForm['birthMonth'] : (data.birthMonth ? data.birthMonth : '01')}`
                      + `-${this.isReadOnlyBirthDay ? this.initialForm['birthDay'] : (data.birthDay ? data.birthDay : '01')}`;
      const body = {
        first_name: this.isReadOnlyFirstName ? this.initialForm['firstName'] : data.firstName,
        last_name: this.isReadOnlyLastName ? this.initialForm['lastName'] : data.lastName,
        email: this.email,
        phone: (data.phone + '').length ? data.phone : '',
        birthday: birthday,
        country_id: this.isReadOnlyCitizenship ? this.initialForm['country'] : data.country,
        residence_country_id: this.isReadOnlyResidenceCountry ? this.initialForm['residence_country_id'] : data.residence_country_id,
        state: this.isReadOnlyState ? this.initialForm['stateOrProvince'] : data.stateOrProvince,
        city: this.isReadOnlyCity ? this.initialForm['city'] : data.city,
        address: this.isReadOnlyStreetAddress ? this.initialForm['street'] : data.street,
        apt: this.isReadOnlyAptOrUnit ? this.initialForm['aptOrUnit'] : data.aptOrUnit,
        zip: this.isReadOnlyZip ? this.initialForm['postalOrZipcode'] : data.postalOrZipcode
      };

      // console.log('to server', body);
      this.dataService.updateProfile(body)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((responseData: MyProfileInterface) => {
          // console.log('server response:', responseData);
          const separator = this.reCaptcha_PROD_is_visible ? '/' : '-';
          this.updateFormWithData(responseData, separator);
          this.successMessage = 'Personal data is updated.';
          this.defaultMessage = '';
          this.dataService.passResidenceCountry(+responseData.residence_country_id);
          this.dataService.setResidenceCountry(+responseData.residence_country_id);
          this.dataService.passCitizenshipCountry(+responseData.country_id);
          this.dataService.setCitizenshipCountry(+responseData.country_id);
          this.dataService.passUsersProfile(responseData);
          this.dataService.setUsersProfile(responseData);

          const isResidenceCountryInBlackList = this.dataService.doVerifyIfResidenceCountryIsInBlackList(
            +responseData.residence_country_id
          );
          const isCitizenshipInBlackList = this.dataService.doVerifyIfCitizenshipIsInBlackList(+responseData.country_id);
          if (isResidenceCountryInBlackList || isCitizenshipInBlackList) {
            this.blockUserActivity();
          }
          this.checkGeoForBlackListedCountry();
          this.isPending = false;
          localStorage.setItem('isPersonalDataUpdated', JSON.stringify(true));
          this.checkReadOnlyFields(responseData);
          this.router.navigateByUrl('user-settings/identity-verification').then();
        }, error => {
          console.log(error);
          this.isPending = false;
          this.successMessage = error.error.message || error.message;
        });
    }
  }

  public blockUserActivity() {
    if (environment.name === 'prod') {
      this.router.navigate(['/login']).then();
      this.dataService.setLoginIncomeErrorStatus(['isGeoBlockedCountry']);
      this.dataService.passLoginIncomeErrorStatus(['isGeoBlockedCountry']);
      this.authService.logout();
    }
  }

  public checkGeoForBlackListedCountry() {
    // start GEO blocking
    let isGeoCountryIsInBlackList: boolean;
    let isGeoCountryIsInRestricktedList: boolean;
    let userIsoCountry = this.dataService.getUserIsoCountry();
    if (userIsoCountry && userIsoCountry.length) {
      this.dataService.doVerifyIfGeoCountryIsInBlackListAndInRestricktedList(userIsoCountry);
      isGeoCountryIsInBlackList = this.dataService.getIsGeoCountryIsInBlackList();
      isGeoCountryIsInRestricktedList = this.dataService.getIsGeoCountryIsInRestricktedList();
      if (isGeoCountryIsInBlackList) {
        this.isGeoBlockedCountry = true;
        this.blockUserActivity();
      }
    } else {
      this.dataService.getUserIsoCountryEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response1 => {
          userIsoCountry = response1;
          if (userIsoCountry && userIsoCountry.length) {
            this.dataService.doVerifyIfGeoCountryIsInBlackListAndInRestricktedList(userIsoCountry);
            isGeoCountryIsInBlackList = this.dataService.getIsGeoCountryIsInBlackList();
            isGeoCountryIsInRestricktedList = this.dataService.getIsGeoCountryIsInRestricktedList();
            if (isGeoCountryIsInBlackList) {
              this.isGeoBlockedCountry = true;
              this.blockUserActivity();
            }
          }
        });
      this.dataService.getIsGeoCountryIsInBlackListEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response2 => {
          isGeoCountryIsInBlackList = response2;
          // console.log('isGeoCountryIsInBlackList', isGeoCountryIsInBlackList);
          if (isGeoCountryIsInBlackList) {
            this.isGeoBlockedCountry = true;
            this.blockUserActivity();
          }
        });
      this.dataService.getIsGeoCountryIsInRestricktedListEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response2 => {
          isGeoCountryIsInRestricktedList = response2;
          // console.log('isGeoCountryIsInRestricktedList', isGeoCountryIsInRestricktedList);
        });
    }
    // end GEO blocking

  }

  public validateDuringInput(field: string) {
    const initialValue = <string>this.profileForm.controls[field].value;
    let data: any;
    switch (field) {
      case 'firstName':
        this.errors.firstNameIsNotInEnglish = false;
        this.errors.firstNameIsEmpty = false;
        data = this.validationService.validateEnglishLettersNumbersSymbols(initialValue);
        if (initialValue !== data && initialValue.length) {this.errors.firstNameIsNotInEnglish = true; }
        if (this.profileForm.controls[field].invalid) {this.errors.firstNameIsEmpty = true; }
        this.profileForm.controls[field].setValue(data);
        break;
      case 'lastName':
        this.errors.lastNameIsNotInEnglish = false;
        this.errors.lastNameIsEmpty = false;
        data = this.validationService.validateEnglishLettersNumbersSymbols(initialValue);
        if (initialValue !== data && initialValue.length) {this.errors.lastNameIsNotInEnglish = true; }
        if (this.profileForm.controls[field].invalid) {this.errors.lastNameIsEmpty = true; }
        this.profileForm.controls[field].setValue(data);
        break;
      case 'city':
        this.errors.cityIsNotInEnglish = false;
        this.errors.cityIsEmpty = false;
        data = this.validationService.validateEnglishLettersNumbersSymbols(initialValue);
        if (initialValue !== data && initialValue.length) {this.errors.cityIsNotInEnglish = true; }
        if (this.profileForm.controls[field].invalid) {this.errors.cityIsEmpty = true; }
        this.profileForm.controls[field].setValue(data);
        break;
      case 'street':
        this.errors.streetIsNotInEnglish = false;
        this.errors.streetIsEmpty = false;
        data = this.validationService.validateEnglishLettersNumbersSymbols(initialValue);
        if (initialValue !== data && initialValue.length) {this.errors.streetIsNotInEnglish = true; }
        if (this.profileForm.controls[field].invalid) {this.errors.streetIsEmpty = true; }
        this.profileForm.controls[field].setValue(data);
        break;
      case 'aptOrUnit':
        this.errors.aptOrUnitIsNotInEnglish = false;
        this.errors.aptOrUnitIsEmpty = false;
        data = this.validationService.validateEnglishLettersNumbersSymbols(initialValue);
        if (initialValue !== data && initialValue.length) {this.errors.aptOrUnitIsNotInEnglish = true; }
        if (this.profileForm.controls[field].invalid) {this.errors.aptOrUnitIsEmpty = true; }
        this.profileForm.controls[field].setValue(data);
        break;
      case 'stateOrProvince':
        this.errors.stateOrProvinceIsNotInEnglish = false;
        this.errors.stateOrProvinceIsEmpty = false;
        data = this.validationService.validateEnglishLettersNumbersSymbols(initialValue);
        if (initialValue !== data && initialValue.length) {this.errors.stateOrProvinceIsNotInEnglish = true; }
        if (this.profileForm.controls[field].invalid) {this.errors.stateOrProvinceIsEmpty = true; }
        this.profileForm.controls[field].setValue(data);
        break;
      case 'postalOrZipcode':
        this.errors.postalOrZipcodeIsNotInEnglish = false;
        this.errors.postalOrZipcodeIsEmpty = false;
        data = this.validationService.validateEnglishLettersNumbersSymbols(initialValue);
        if (initialValue !== data && initialValue.length) {this.errors.postalOrZipcodeIsNotInEnglish = true; }
        if (this.profileForm.controls[field].invalid) {this.errors.postalOrZipcodeIsEmpty = true; }
        this.profileForm.controls[field].setValue(data);
        break;
      case 'birthMonth':
        this.errors.birthMonthIsEmpty = false;
        if (this.profileForm.controls[field].invalid) {this.errors.birthMonthIsEmpty = true; }
        break;
      case 'birthDay':
        this.errors.birthDayIsEmpty = false;
        if (this.profileForm.controls[field].invalid) {this.errors.birthDayIsEmpty = true; }
        break;
      case 'birthYear':
        this.errors.birthYearIsEmpty = false;
        if (this.profileForm.controls[field].invalid) {this.errors.birthYearIsEmpty = true; }
        break;
      case 'country':
        this.errors.citizenShipIsEmpty = false;
        if (this.profileForm.controls[field].invalid) {this.errors.citizenShipIsEmpty = true; }
        break;
      case 'residence_country_id':
        this.errors.residenceCountryIsEmpty = false;
        if (this.profileForm.controls[field].invalid) {this.errors.residenceCountryIsEmpty = true; }
        break;
    }
  }

  public saveProfileData(field: string) {
    switch (field) {
      case 'residence_country_id':
        // console.log(this.residenceCountryIdValue);
        // console.log('doVerfyIfResidenceCountryIsInBlackList',
        //             this.dataService.doVerifyIfResidenceCountryIsInBlackList(+this.residenceCountryIdValue));
        // this.isResidenceCountryInBlackList = this.dataService.doVerifyIfResidenceCountryIsInBlackList(+this.residenceCountryIdValue);
        break;
      case 'country':
        // console.log(this.residenceCountryIdValue);
        // console.log('doVerifyIfCitizenshipIsInBlackList',
        //             this.dataService.doVerifyIfCitizenshipIsInBlackList(+this.citizenshipIdValue));
        // this.isCitizenshipInBlackList = this.dataService.doVerifyIfCitizenshipIsInBlackList(+this.citizenshipIdValue);
        break;
      default:
        break;
    }

    if (this.isChangedFormData(field)) {
      // this.updateProfile();
    } else {
      // console.log('no changes...');
    }
  }

  public saveTempData(field: string) {
    this._profileForm[field] = this.profileForm.controls[field].value;
    // console.log(this._profileForm);
  }
  public isChangedFormData(field: string): boolean {
    return this._profileForm[field] !== this.profileForm.controls[field].value;
  }
  public checkForPhoneInput() {
    if (this.isReadOnlyFirstName
      && this.isReadOnlyLastName
      && this.isReadOnlyBirthMonth
      && this.isReadOnlyBirthDay
      && this.isReadOnlyBirthYear
      && this.isReadOnlyCitizenship
      && this.isReadOnlyCity
      && this.isReadOnlyStreetAddress
      && this.isReadOnlyAptOrUnit
      && this.isReadOnlyState
      && this.isReadOnlyZip
      && this.isReadOnlyResidenceCountry) {
      this.isVisibleSubmitButton = this.isChangedAnyFormData() && this.profileForm.valid && ((this.phoneValue + '').length > 0);
    } else {
      this.isVisibleSubmitButton = this.isChangedAnyFormData();
    }
  }
  public checkReferralCode() {
    this.isReferralCode = this.profileForm.value.phone === 'BG2020';
  }
  public isReferralCodeWithoutTick() {
    return this.isReferralCode && !this.profileForm.value.isAgree;
  }
  public isChangedAnyFormData(): boolean {
    let rez = false;
    this.keysProfileForm.forEach(key => {
      if (this.isChangedFormData(key)) {
        rez = true;
      }
    });
    return rez;
  }
  public setDay(day: string): string {
    if (day && (day).toString().length === 1) {
      return '0' + (day);
    }
    return (day && (day).toString()) ? (day).toString() : '';
  }
  public setMonth(month: string): string {
    if (month && (month).toString().length === 1) {
      return '0' + (month);
    }
    return (month && (month).toString()) ? (month).toString() : '';
  }
  private setDaysAndMonths(): void {
    this.days = new Array(31).fill(1)
      .map((v, i) => {
        if ((i + 1).toString().length === 1) {
          return '0' + (i + 1);
        }

        return (i + 1).toString();
      });
    const today = new Date().getFullYear() - 99;
    this.years = new Array(82).fill(0)
      .map((v, i) => {
        return i + today;
      });
    this.months = [
      {name: 'Jan', number: '01'},
      {name: 'Feb', number: '02'},
      {name: 'Mar', number: '03'},
      {name: 'Apr', number: '04'},
      {name: 'May', number: '05'},
      {name: 'Jun', number: '06'},
      {name: 'Jul', number: '07'},
      {name: 'Aug', number: '08'},
      {name: 'Sep', number: '09'},
      {name: 'Oct', number: '10'},
      {name: 'Nov', number: '11'},
      {name: 'Dec', number: '12'}];
  }

  private createForm(): void {
    this.profileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthMonth: ['', Validators.required],
      birthDay: ['', Validators.required],
      birthYear: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.maxLength(18)]],
      isAgree: [false],
      country: [undefined, Validators.required],
      residence_country_id: [undefined, Validators.required],
      city: ['', Validators.required],
      street: ['', Validators.required],
      aptOrUnit: ['', Validators.required],
      stateOrProvince: ['', Validators.required],
      postalOrZipcode: ['', Validators.required]
    });
  }

  get phone() { return this.profileForm.get('phone'); }
  get phoneValue() { return this.profileForm.get('phone').value; }
  get citizenshipIdValue() { return this.profileForm.get('country').value; }
  get residenceCountryIdValue() { return this.profileForm.get('residence_country_id').value; }

  get residenceCountryName(): any {
    if (this.countries && this.countries.length) {
      return this.countries.filter(country => (<any>country).id === this.residenceCountryIdValue)[0];
    }
  }
  get citizenshipCountryName(): any {
    if (this.countries && this.countries.length) {
      return this.countries.filter(country => (<any>country).id === this.citizenshipIdValue)[0];
    }
  }

  private createFormWithData(data: MyProfileInterface): void {
    this.profileForm = this.fb.group({
      firstName: [data.first_name || '', Validators.required],
      lastName: [data.last_name || '', Validators.required],
      birthMonth: [data.birthMonth || '', Validators.required],
      birthDay: [data.birthDay || '', Validators.required],
      birthYear: [+data.birthYear || undefined, Validators.required],
      email: [data.email || '', [Validators.required, Validators.email]],
      phone: [(data.phone + '').length ? data.phone : '', [Validators.maxLength(21)]],
      isAgree: [false],
      country: [data.country_id || undefined, Validators.required],
      residence_country_id: [data.residence_country_id || undefined, Validators.required],
      city: [data.city || '', Validators.required],
      street: [data.address || '', Validators.required],
      aptOrUnit: [data.apt || '', Validators.required],
      stateOrProvince: [data.state || '', Validators.required],
      postalOrZipcode: [data.zip || '', Validators.required]
    });
    this._profileForm = Object.assign({}, this.profileForm.value);
    this.initialForm = Object.assign({}, this.profileForm.value);
    this.keysProfileForm = Object.keys(this.profileForm.value);
    // console.log(this.profileForm.value);
  }

  private checkReadOnlyFields(data: MyProfileInterface): void {
    this.isReadOnlyFirstName = data.first_name && data.first_name.length > 0;
    if (this.isReadOnlyFirstName) {this.profileForm.controls['firstName'].disable(); }

    this.isReadOnlyLastName = data.last_name && data.last_name.length > 0;
    if (this.isReadOnlyLastName) {this.profileForm.controls['lastName'].disable(); }

    this.isReadOnlyBirthMonth = data.birthMonth && data.birthMonth.length > 0;
    if (this.isReadOnlyBirthMonth) {this.profileForm.controls['birthMonth'].disable(); }

    this.isReadOnlyBirthDay = data.birthDay && data.birthDay.length > 0;
    if (this.isReadOnlyBirthDay) {this.profileForm.controls['birthDay'].disable(); }

    this.isReadOnlyBirthYear = data.birthYear && data.birthYear.length > 0;
    if (this.isReadOnlyBirthYear) {this.profileForm.controls['birthYear'].disable(); }

    this.isReadOnlyCitizenship = data.country_id && data.country_id > 0; //country
    if (this.isReadOnlyCitizenship) {this.profileForm.controls['country'].disable(); }

    this.isReadOnlyCity = data.city && data.city.length > 0;
    if (this.isReadOnlyCity) {this.profileForm.controls['city'].disable(); }

    this.isReadOnlyStreetAddress = data.address && data.address.length > 0;
    if (this.isReadOnlyStreetAddress) {this.profileForm.controls['street'].disable(); }

    this.isReadOnlyAptOrUnit = data.apt && data.apt.length > 0;
    if (this.isReadOnlyAptOrUnit) {this.profileForm.controls['aptOrUnit'].disable(); }

    this.isReadOnlyState = data.state && data.state.length > 0;
    if (this.isReadOnlyState) {this.profileForm.controls['stateOrProvince'].disable(); }

    this.isReadOnlyZip = data.zip && data.zip.length > 0;
    if (this.isReadOnlyZip) {this.profileForm.controls['postalOrZipcode'].disable(); }

    this.isReadOnlyResidenceCountry = data.country_id && data.country_id > 0;
    if (this.isReadOnlyResidenceCountry) {this.profileForm.controls['residence_country_id'].disable(); }

    // console.log(this.profileForm);
    this.checkVisibilitySubmitButton();
  }

  private checkIfEmptyFields(): void {
    if (this.profileForm.controls['firstName'].invalid) {this.errors.firstNameIsEmpty = true; }
    if (this.profileForm.controls['lastName'].invalid) {this.errors.lastNameIsEmpty = true; }
    if (this.profileForm.controls['city'].invalid) {this.errors.cityIsEmpty = true; }
    if (this.profileForm.controls['street'].invalid) {this.errors.streetIsEmpty = true; }
    if (this.profileForm.controls['aptOrUnit'].invalid) {this.errors.aptOrUnitIsEmpty = true; }
    if (this.profileForm.controls['stateOrProvince'].invalid) {this.errors.stateOrProvinceIsEmpty = true; }
    if (this.profileForm.controls['postalOrZipcode'].invalid) {this.errors.postalOrZipcodeIsEmpty = true; }
    if (this.profileForm.controls['birthMonth'].invalid) {this.errors.birthMonthIsEmpty = true; }
    if (this.profileForm.controls['birthDay'].invalid) {this.errors.birthDayIsEmpty = true; }
    if (this.profileForm.controls['birthYear'].invalid) {this.errors.birthYearIsEmpty = true; }
    if (this.profileForm.controls['country'].invalid) {this.errors.citizenShipIsEmpty = true; }
    if (this.profileForm.controls['residence_country_id'].invalid) {this.errors.residenceCountryIsEmpty = true; }
  }

  private checkVisibilitySubmitButton() {
    this.isVisibleSubmitButton = !(this.isReadOnlyFirstName
                              && this.isReadOnlyLastName
                              && this.isReadOnlyBirthMonth
                              && this.isReadOnlyBirthDay
                              && this.isReadOnlyBirthYear
                              && this.isReadOnlyCitizenship
                              && this.isReadOnlyCity
                              && this.isReadOnlyStreetAddress
                              && this.isReadOnlyAptOrUnit
                              && this.isReadOnlyState
                              && this.isReadOnlyZip
                              && this.isReadOnlyResidenceCountry);
  }
  private updateFormWithData(data: MyProfileInterface, separator: string): void {
    // console.log(data.birthday.split(separator));
    this.profileForm.controls['firstName'].setValue(data.first_name);
    this.profileForm.controls['lastName'].setValue(data.last_name);
    const month = this.reCaptcha_PROD_is_visible
      ? ((data.birthMonth && data.birthMonth.length)
        ? data.birthMonth
        : (data.birthday && data.birthday.length) ? this.setMonth(data.birthday.split(separator)[0]) : undefined)
      : ((data.birthMonth && data.birthMonth.length)
        ? data.birthMonth
        : (data.birthday && data.birthday.length) ? this.setMonth(data.birthday.split(separator)[1]) : undefined);
    this.profileForm.controls['birthMonth'].setValue(month);
    const day = this.reCaptcha_PROD_is_visible
      ? ((data.birthDay && data.birthDay.length)
        ? data.birthDay
        : (data.birthday && data.birthday.length) ? this.setDay(data.birthday.split(separator)[1]) : undefined)
      : ((data.birthDay && data.birthDay.length)
        ? data.birthDay
        : (data.birthday && data.birthday.length) ? this.setDay(data.birthday.split(separator)[2]) : undefined);
    this.profileForm.controls['birthDay'].setValue(day);
    const year = this.reCaptcha_PROD_is_visible
      ? ((data.birthYear && +data.birthYear > 0)
        ? +data.birthYear
        : (data.birthday && data.birthday.length) ? +data.birthday.split(separator)[2] : undefined)
      : ((data.birthYear && +data.birthYear > 0)
        ? +data.birthYear
        : (data.birthday && data.birthday.length) ? +data.birthday.split(separator)[0] : undefined);
    this.profileForm.controls['birthYear'].setValue(year);
    this.profileForm.controls['email'].setValue(data.email);
    this.profileForm.controls['phone'].setValue('' + data.phone);
    this.profileForm.controls['country'].setValue(data.country_id);
    this.profileForm.controls['residence_country_id'].setValue(data.residence_country_id);
    this.profileForm.controls['city'].setValue(data.city);
    this.profileForm.controls['street'].setValue(data.address);
    this.profileForm.controls['aptOrUnit'].setValue(data.apt);
    this.profileForm.controls['stateOrProvince'].setValue(data.state);
    this.profileForm.controls['postalOrZipcode'].setValue(data.zip);
    this._profileForm = Object.assign({}, this.profileForm.value);
  }

  public resetErrors() {
    this.errors.firstNameIsNotInEnglish = false;
    this.errors.lastNameIsNotInEnglish = false;
    this.errors.cityIsNotInEnglish = false;
    this.errors.streetIsNotInEnglish = false;
    this.errors.aptOrUnitIsNotInEnglish = false;
    this.errors.stateOrProvinceIsNotInEnglish = false;
    this.errors.postalOrZipcodeIsNotInEnglish = false;
    this.errors.firstNameIsEmpty = false;
    this.errors.lastNameIsEmpty = false;
    this.errors.cityIsEmpty = false;
    this.errors.streetIsEmpty = false;
    this.errors.aptOrUnitIsEmpty = false;
    this.errors.stateOrProvinceIsEmpty = false;
    this.errors.postalOrZipcodeIsEmpty = false;
  }

  private getIsVisibleProfileEmptyFields(): void {
    this.isVisibleEmptyFields = this.dataService.getIsVisibleProfileEmptyFields();
    this.dataService.getIsVisibleProfileEmptyFieldsEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isVisibleEmptyFields = data;
      });
  }

  private getArrayNotCompletedFields(): void {
    this.arrayNotCompletedProfileFields = this.dataService.getArrayNotCompletedProfileFields();
    this.dataService.getArrayNotCompletedProfileFieldsEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.arrayNotCompletedProfileFields = data;
      });
  }

  public checkIfTheFieldIsEmpty(field: string): boolean {
    if (this.arrayNotCompletedProfileFields && this.arrayNotCompletedProfileFields.length) {
      return this.arrayNotCompletedProfileFields.includes(field);
    } else {
      return false;
    }
  }

}
