import {Component} from '@angular/core';
import {DataService} from './services/data.service';
import {AlertComponent} from './dialogs/alert/alert.component';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../environments/environment';
import {TitleService} from './services/title.service';
import {ViewStateService} from './services/view-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLightTheme = false;
  public reCaptcha_version = environment.name || 'prod';
  public displayHeader$ = this._viewState.displayHeader$;

  constructor(public dataService: DataService,
              private titleService: TitleService,
              private dialog: MatDialog,
              private _viewState: ViewStateService,
  ) {
    this.titleService.init();
    const keys = Object.keys(localStorage);
    const tradingviews = [].concat(this.getKeysByTemplate('tradingview', keys), this.getKeysByTemplate('tvxwevents', keys));
    let isCreated = false;
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === 'tradingview.defIsUpdated') {
        isCreated = true;
        const defIsUpdated = localStorage.getItem('tradingview.defIsUpdated');
        switch (defIsUpdated) {
          case '1':
            // console.log('********* update is loaded');
            break;
          default:
            this.removeLSkeys(tradingviews);
        }
      }
    }
    if (!isCreated) {
      localStorage.setItem('tradingview.defIsUpdated', '1');
      this.removeLSkeys(tradingviews);
    }
    setTimeout(() => this.getMessageTypeForAlertModal(), 100);
    // this.dataService.passMessageTypeForAlertModal(1);
    // this.dataService.setMessageTypeForAlertModal(1);

    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .subscribe(data => {
        this.isLightTheme = data;
      });

    // if (
    //   this.reCaptcha_version === 'prod'
    // ) {
    setTimeout(() => {
      this.dataService.findUserCountry();
    }, 500);
    // }
  }

  public getMessageTypeForAlertModal() {
    let messageType = this.dataService.getMessageTypeForAlertModal();
    if (messageType) {
      this.openAlertModal(messageType);
    }
    this.dataService.getMessageTypeForAlertModalEmitter()
      .subscribe(data => {
        messageType = data;
        if (messageType) {
          this.openAlertModal(messageType);
        }
      });
  }

  public openAlertModal(messageType: number, messageText?: string) {
    if (messageType) {
      this.dialog.open(AlertComponent, {
        data: {'messageType': messageType, 'messageText': messageText || 'no message'}
      });
    }
  }

  public getKeysByTemplate(template: string, keys: Array<string>): Array<string> {
    const result = [];
    for (let i = 0; i < keys.length; i++) {
      if (!(keys[i].toLowerCase().indexOf(template.toLowerCase()) === -1)) {
        result.push(keys[i]);
      }
    }
    return result;
  }

  public removeLSkeys(keysToRemove: Array<string>) {
    for (let i = 0; i < keysToRemove.length; i++) {
      localStorage.removeItem(keysToRemove[i]);
    }
  }
}
