import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {IS_GEO_TURNED_ON, RESTRICTED_COUNTRIES} from '../../app.constants';
import {RestrictedCountriesClass} from '../../models/restricted-countries.class';
import {takeUntil} from 'rxjs/operators';
import {MyProfileInterface} from '../../models/my-profile.model';
import {Subject} from 'rxjs/internal/Subject';
import {environment} from '../../../environments/environment';
import {TokensInterface} from '../../models/tokens.model';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss']
})
export class LogInComponent implements OnDestroy, OnInit {
  private customRedirect: boolean;
  public loginForm: FormGroup;
  public isEnabled2Fa = false;
  public isChecked2Fa = false;
  public userCountry = new RestrictedCountriesClass(undefined, undefined);
  public userCity = '';
  public xhr;
  // public reCaptcha_version = RECAPTCHA_VERSION;
  public reCaptcha_version = environment.name || 'prod';
  public restrictedCounries = RESTRICTED_COUNTRIES;
  public isRestrictedCountry = false;
  public isGeoBlockedCountry = false;
  public isGeoBlockedCity = false;
  public isAustralianUser = false;
  public userIsoCountry: string;
  public errorMessage = '';
  public isGEOturnedON = IS_GEO_TURNED_ON;
  public errors = {
    verifyEmail: {
      notSent: false,
      sent: false
    },
    wrongEmailOrPass: false,
    tFaEnabled: false,
    tFaInvalid: false,
    userNotFound: false,
    flagDowJohns: false,
    kycBlocked: false,
    kycRejected: false
  };
  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();
  public pendingRequest: boolean;
  public pending2FaRequest: boolean;

  constructor(public dialogRef: MatDialogRef<LogInComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public fb: FormBuilder,
              private authService: AuthService,
              private router: Router,
              private dataService: DataService) {
    if (
      this.reCaptcha_version === 'prod'
    // || this.reCaptcha_version === 'auprod'
    ) {
      setTimeout(() => {
        this.dataService.findUserCountry();
      }, 500);
    }

    this.loadUserCountry();
    this.createForm();
    this.dataService.getBlackListCountriesData();

    this.dialogRef.afterClosed()
      .subscribe(() => {
        if (!this.customRedirect) {
          this.router.navigate(['/markets']).then();
        } else {
          if (localStorage.getItem('selected_currency')) {
            this.router.navigate(['/user-settings/advcash']).then();
          }
        }
        this.isRestrictedCountry = false;
      });

    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }

  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.dialogRef.close();
      this.router.navigate(['/markets']).then();
    }
    const errors1 = this.dataService.getLoginIncomeErrorStatus();
    this.userIsoCountry = this.dataService.getUserIsoCountry();
    this.dataService.getUserIsoCountryEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((response: string) => {
        this.userIsoCountry = response;
      });
    if (errors1 && errors1.length) {
      errors1.forEach(error => {
        if (error === 'isGeoBlockedCountry') {
          this.isGeoBlockedCountry = true;
        }
      });
    }
    // console.log(this.isGeoBlockedCountry);
    this.dataService.getLoginIncomeErrorStatusEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((errors: Array<string>) => {
        // console.log(errors);
        this.userIsoCountry = this.dataService.getUserIsoCountry();
        if (errors && errors.length) {
          errors.forEach(error => {

            if (error === 'isGeoBlockedCountry') {
              this.isGeoBlockedCountry = true;
            }
          });
        }
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public login(): void {
    if (!this.isRestrictedCountry) {
      this.pendingRequest = true;
      this.resetErrors();
      const loginData = {
        email: this.dataService.getEmailLowercase(this.loginForm.value.email),
        password: this.loginForm.value.password,
        rememberMe: this.loginForm.value.rememberMe,
        code: this.loginForm.value.tFaCode
      };
      // myRecaptcha: this.loginForm.value.myRecaptcha
      // };
      if (this.loginForm.value.tFaCode && this.isEnabled2Fa) {
        this.loginWith2Fa(loginData);
      } else if (!this.isEnabled2Fa) {
        this.loginWithout2Fa(loginData);
      }
    }
  }

  public loadUserCountry() {
    const userCountry = this.dataService.getUserCountry();
    this.userCountry.ISO = userCountry && userCountry.ISO ? userCountry.ISO : undefined;
    this.userCountry.name = userCountry && userCountry.name ? userCountry.name : undefined;
    if (this.userCountry.ISO && this.userCountry.ISO.length) {
      // this.verifyForRestrictedCountry(this.userCountry.ISO);
    }
    this.dataService.getUserCountryEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(_userCountry => {
        this.userCountry.ISO = _userCountry && _userCountry.ISO ? _userCountry.ISO : undefined;
        this.userCountry.name = _userCountry && _userCountry.name ? _userCountry.name : undefined;
        if (this.userCountry.ISO && this.userCountry.ISO.length) {
          // this.verifyForRestrictedCountry(this.userCountry.ISO);
        }
      });
  }

  verifyForRestrictedCountry(iso: string) {
    this.isRestrictedCountry = this.restrictedCounries.some((country) => {
      return country.ISO === iso;
    });
  }

  public redirectClose() {
    this.customRedirect = true;
    this.dialogRef.close();
  }

  public resendVerification(): void {
    this.errors.verifyEmail.notSent = false;
    this.authService.resendCheckEmail(this.dataService.getEmailLowercase(this.loginForm.value.email))
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => this.errors.verifyEmail.sent = true);
  }

  private createForm(): void {
    const isProdEnv = (this.reCaptcha_version === 'prod') || (this.reCaptcha_version === 'auprod');
    this.loginForm = this.fb.group({
      // email: ['tasik.ua@ukr.net', [Validators.required, Validators.email]],
      // email: ['diazz.turdiyev@gmail.com', [Validators.required, Validators.email]],   //     with money!!
      // email: ['rybin.g@ideasoft.io', [Validators.required, Validators.email]],
      // email: ['biteeu.test@rambler.ru', [Validators.required, Validators.email]],
      // email: ['gena1671@ukr.net', [Validators.required, Validators.email]],
      email: ['', [Validators.required, Validators.email]],
      // password: ['Iqos123*', Validators.required],                   //   diazz.turdiyev@mail.ru
        //  password: ['#1Qaz111', Validators.required],                       // tasik.ua@ukr.net
      //  password: ['Ideasoft2018!', Validators.required],                  //  rybin.g@ideasoft.io
      password: ['', Validators.required],
      tFaCode: [''],
      rememberMe: false,
      myRecaptcha: ['', isProdEnv ? Validators.required : []]

    });
  }

  public loginFormChanged(controlName: string): void {
    switch (controlName) {
      case 'email':
        this.resetErrors()
        // this.errors.tFaEnabled = false;
        // this.errors.userNotFound = false;
        // this.isChecked2Fa = false;
        // this.isEnabled2Fa = false;
        break;
      case 'password':
        this.errors.wrongEmailOrPass = false;
        break;

    }
  }

  public check2FaIsEnabled(): void {
    if (this.loginForm.controls['email'] && this.loginForm.controls['email'].valid) {
      this.pending2FaRequest = true;
      this.authService.check2FaIsEnabled(this.loginForm.controls['email'].value)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response => {
            this.pending2FaRequest = false;
            this.isChecked2Fa = true;
            this.isEnabled2Fa = response.success;
            if (this.isEnabled2Fa) {
              this.errors.tFaEnabled = true;
              this.loginForm.controls['tFaCode'].enable();
            } else {
              this.loginForm.controls['tFaCode'].disable();
            }
          },
          err => {
            this.handleError(err);
            this.pending2FaRequest = false;
            this.isChecked2Fa = true;
          });
    }
  }

  private loginWithout2Fa(loginData): void {
    if (!this.isEnabled2Fa) {
      this.authService.login(loginData)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response => {
            // console.log(response);
            this.handleSuccess(response);
          },
          err => {
            console.log(err);
            this.handleError(err);
          });
    }
  }

  private loginWith2Fa(loginData): void {
    if (this.isEnabled2Fa && this.loginForm.value.tFaCode.length === 6) {
      this.authService.login2Fa(loginData)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(response => {
            this.handleSuccess(response);
          },
          err => {
            console.log('loginWith2Fa error', err);
            this.handleError(err);
          });
    }
  }

  private runVerificationForAustralianUser() {
    const userIsoCountry = this.dataService.getUserIsoCountry() || this.userIsoCountry;
    // if ((this.reCaptcha_version === 'prod' && userIsoCountry === 'AU' && this.isGEOturnedON)
    //     || (this.reCaptcha_version === 'auprod' && userIsoCountry !== 'AU' && this.isGEOturnedON)) {
    //     this.isAustralianUser = true;
    // }
  }

  private handleSuccess(response): void {
    this.dataService.userId.next(null);
    this.dataService.userIdData = null;
    if (response.accessToken) {
      this.authService.storeToken(response);
      this.checkForBrowserToRememberTheUser(response);
      this.dataService.getUserProfile()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((res: MyProfileInterface) => {
          if(localStorage.getItem('selected_currency')) {
            this.customRedirect = true;
          }
          // this.router.navigate(['/markets']).then();
          this.dataService.passResidenceCountry(+res.residence_country_id);
          this.dataService.setResidenceCountry(+res.residence_country_id);
          this.dataService.passCitizenshipCountry(+res.country_id);
          this.dataService.setCitizenshipCountry(+res.country_id);
          this.dataService.passUserId(res.id);
          this.dataService.setUserId(res.id);
          this.dataService.passUsersProfile(res);
          this.dataService.setUsersProfile(res);
          localStorage.setItem('visible-set', '' + res.id);
          const isResidenceCountryInBlackList = this.dataService.doVerifyIfResidenceCountryIsInBlackList(+res.residence_country_id);
          const isCitizenshipInBlackList = this.dataService.doVerifyIfCitizenshipIsInBlackList(+res.country_id);

          // start GEO blocking
          let isGeoCountryIsInBlackList: boolean;
          let isGeoCountryIsInRestricktedList: boolean;
          let userIsoCountry = this.dataService.getUserIsoCountry();
          this.userIsoCountry = this.dataService.getUserIsoCountry();
          if (userIsoCountry && userIsoCountry.length) {
            this.dataService.doVerifyIfGeoCountryIsInBlackListAndInRestricktedList(userIsoCountry);
            isGeoCountryIsInBlackList = this.dataService.getIsGeoCountryIsInBlackList();
            isGeoCountryIsInRestricktedList = this.dataService.getIsGeoCountryIsInRestricktedList();
            if (isGeoCountryIsInBlackList) {
              this.isGeoBlockedCountry = true;
              this.authService.logout();
            }
          } else {
            this.dataService.getUserIsoCountryEmitter()
              .pipe(takeUntil(this.destroySubject$))
              .subscribe((response1: string) => {
                userIsoCountry = response1;
                if (userIsoCountry && userIsoCountry.length) {
                  this.dataService.doVerifyIfGeoCountryIsInBlackListAndInRestricktedList(userIsoCountry);
                  isGeoCountryIsInBlackList = this.dataService.getIsGeoCountryIsInBlackList();
                  isGeoCountryIsInRestricktedList = this.dataService.getIsGeoCountryIsInRestricktedList();
                  if (isGeoCountryIsInBlackList) {
                    this.isGeoBlockedCountry = true;
                    this.authService.logout();
                  }
                }
              });
            this.dataService.getIsGeoCountryIsInBlackListEmitter()
              .pipe(takeUntil(this.destroySubject$))
              .subscribe(response2 => {
                isGeoCountryIsInBlackList = response2;
                // console.log('isGeoCountryIsInBlackList', isGeoCountryIsInBlackList);
                if (isGeoCountryIsInBlackList) {
                  this.isGeoBlockedCountry = true;
                  this.authService.logout();
                }
              });
            this.dataService.getIsGeoCountryIsInRestricktedListEmitter()
              .pipe(takeUntil(this.destroySubject$))
              .subscribe(response2 => {
                isGeoCountryIsInRestricktedList = response2;
                // console.log('isGeoCountryIsInRestricktedList', isGeoCountryIsInRestricktedList);
              });
          }

          // city GEO blocking
          let userCity = this.dataService.getUserCity();
          this.userCity = userCity;

          if (userCity && userCity.length) {
            const isGeoCityIsInBlackList = this.dataService.doVerifyIfGeoCityIsInBlackList(userCity);
            if (isGeoCityIsInBlackList) {
              this.pendingRequest = false;
              this.isGeoBlockedCity = true;
              this.authService.logout();
              return;
            }
          } else {
            this.dataService.getUserCityEmitter()
              .pipe(takeUntil(this.destroySubject$))
              .subscribe(response => {
                userCity = response;
                if (userCity && userCity.length) {
                  const isGeoCityIsInBlackList = this.dataService.doVerifyIfGeoCityIsInBlackList(userCity);
                  if (isGeoCityIsInBlackList) {
                    this.pendingRequest = false;
                    this.isGeoBlockedCity = true;
                    this.authService.logout();
                    return;
                  }
                }
              });
            }
          // end GEO blocking

          this.getKycStatus();
          setTimeout(() => this.postUpdateWallets(res.id), 100);

          if (isResidenceCountryInBlackList || isCitizenshipInBlackList
            || isGeoCountryIsInBlackList) {
            if (isResidenceCountryInBlackList || isCitizenshipInBlackList) {
              this.isRestrictedCountry = true;
              if (isResidenceCountryInBlackList && isCitizenshipInBlackList === false) {
                this.userCountry.name = 'your residence country';
              }
              if (isResidenceCountryInBlackList === false && isCitizenshipInBlackList) {
                this.userCountry.name = 'your citizenship country';
              }
              if (isResidenceCountryInBlackList && isCitizenshipInBlackList) {
                this.userCountry.name = 'yours residence and citizenship countries';
              }
            }
            this.pendingRequest = false;
            setTimeout(() => {
              this.router.navigate(['/login']).then();
              this.authService.logout();
            }, 3000);
          } else {
            this.dialogRef.close();
            this.authService.isLoggedIn = true;
            this.dataService.getCurrenciesListFromServer();
          }
          if (res.cookies_agreed === 0 || res.cookies_agreed === 1) {
            this.dataService.passUserIsCokiesAgreed(res.cookies_agreed);
            this.dataService.setUserIsCokiesAgreed(res.cookies_agreed);
          }
        });
    } else {
      this.customRedirect = true;
      this.dialogRef.close();
      this.router.navigate(['login-verify']).then();
    }
  }

  private handleError(err): void {
    this.pendingRequest = false;
    this.errorMessage = '';
    if (err.error) {
      switch (err.error.errorCode) {
        case '400_36':
          this.errors.flagDowJohns = true;
          console.log(err.error.message || err.message);
          break;
        case '400_37':
          this.errors.kycRejected = true;
          break;
        case '400_39':
          this.errors.kycBlocked = true;
          break;
        case '401_5':
          this.errors.verifyEmail.notSent = true;
          break;
        case '417_38':
          this.errors.tFaEnabled = true;
          break;
        case '403_1':
          this.errors.wrongEmailOrPass = true;
          break;
        case '404_2':
          this.errors.userNotFound = true;
          break;
        case '401_7':
          this.errors.tFaInvalid = true;
          break;
        default:
          this.errorMessage = err.error.message || err.message.includes('login-totp: 401') ? 'Invalid 2fa code' : err.message;
          break;
      }
    }
  }

  private resetErrors() {
    this.errors.wrongEmailOrPass = false;
    this.errors.tFaEnabled = false;
    this.errors.tFaInvalid = false;
    this.errors.userNotFound = false;
    this.errors.flagDowJohns = false;
    this.errors.kycBlocked = false;
    this.errors.kycRejected = false;
    this.errorMessage = '';
  }

  public getKycStatus() {
    this.dataService.getUserKycStatus()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: any) => {
        // console.log(res);
        const kycStatus = res.status;
        if (kycStatus === 2 || kycStatus === 3) {
          this.isRestrictedCountry = true;
          this.userCountry.name = 'this user';
          this.authService.logout();
        }
      }, error1 => {
        console.log(error1);
      });
  }

  public postUpdateWallets(userId: number) {
    this.dataService.postUpdateWallets(userId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        },
        error => {
          console.log(error);
        });
    this.dataService.postUpdateWithdrawalStatus(userId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        },
        error1 => {
          console.log(error1);
        });
    this.dataService.postCheckPendingWalletsStatus(userId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        },
        error2 => {
          console.log(error2);
        });
  }

  public resolved(captchaResponse: string): void {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    // this.verifyRecapcha({'reCaptchaToken': captchaResponse});
  }

  // public verifyRecapcha(recaphaToken) {
  //   this.userService.verifyRecapcha(recaphaToken).subscribe((response) => {
  //     // console.log('verify recapha', response);
  //   }, (error) => {
  //     console.log('verify recapcha error', error);
  //   });
  // }

  public checkForBrowserToRememberTheUser(tokens: TokensInterface) {
    if (this.rememberMe.value) {
      this.authService.setRememberToken(tokens);
    }
    // (this.rememberMe.value)
    //   ? this.authService.setRememberToken(tokens)
    //   : this.authService.deleteRememberToken();
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  get rememberMe() {
    return this.loginForm.get('rememberMe');
  }
}
