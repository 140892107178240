import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnDestroy {
  public isLightTheme = false;
  public isTestAccount = false;
  destroySubject$: Subject<void> = new Subject();
  constructor(
    public dataService: DataService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }
  @ViewChild('aside', { static: false }) aside: ElementRef;
  @ViewChild('menuspan', { static: false }) menuspan: ElementRef;

  toggle() {
    this.aside.nativeElement.classList.toggle('aside_menu')
    this.menuspan.nativeElement.classList.toggle('rotateArrow')
  }
  ngOnInit() {
    this.isTestAccount = [ 134, 370, 1306 ].some(x => x == this.dataService.getUserId());
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

}
