import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

import * as socketIo from 'socket.io-client';
import { CurrencyInterface } from '../models/currency.model';
import { ThemesModel } from '../models/themes.model';
import { MarketsShortInfoInterface } from '../models/markets-short-info.model';
import {
  IS_GEO_TURNED_ON,
  RESTRICTED_COUNTRIES,
  RESTRICTED_CITIES,
  USERS_AUTHORISED_FOR_FIAT_MODAL,
  USERS_AUTHORISED_FOR_STOP_LOSS,
} from '../app.constants';
import { RestrictedCountriesClass } from '../models/restricted-countries.class';
import { HttpClient } from '@angular/common/http';
import { MyProfileInterface } from '../models/my-profile.model';
import { CountryClass } from '../models/country.class';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FiatClass } from '../models/fiat.class';
import { TickHistoryModel } from '../models/tick-history.model';
import { CurrencyLastPriceClass } from '../models/currency-last-price.class';
import { OrderStopLimitClass } from '../models/order-stop-limit.class';
import { BCXGRedemption } from '../models/bcxg-redemption.model';
import {map, shareReplay, startWith, tap} from 'rxjs/operators';
import {concat} from 'rxjs/internal/observable/concat';
import {of} from 'rxjs/internal/observable/of';
import {BalanceInterface} from '../models/balance.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public market = 'BTC-ETH';
  public SERVER_URL: any;
  private socket: any;
  private orderBookHistory = new Subject<any>();
  private headers: Headers;
  public refreshOrders = new Subject();
  public restrictedCounries = RESTRICTED_COUNTRIES;
  public restrictedCities = RESTRICTED_CITIES;
  public usersAuthorisedForFiatModal = USERS_AUTHORISED_FOR_FIAT_MODAL;
  public usersAuthorisedForStopLossTakeProfit = USERS_AUTHORISED_FOR_STOP_LOSS;
  // public reCaptcha_version = RECAPTCHA_VERSION;
  public reCaptcha_version = environment.name || 'prod';
  public isGEOturnedON = IS_GEO_TURNED_ON;
  public currentTheme: string = ThemesModel.DEFAULT;
  public currentThemeSubject = new BehaviorSubject<string>('Theme 1');

  currenciesList = new Subject<Array<CurrencyInterface>>();
  currenciesListData: Array<CurrencyInterface>;

  marketPairMinOrderAmount = new Subject<MarketsShortInfoInterface>();
  marketPairMinOrderAmountData: MarketsShortInfoInterface;

  marketPairNew = new Subject<string>();
  public refreshedStopLimitOrders = new Subject();


  userId = new Subject<number>();
  userIdData: number;

  userProfile = new Subject<MyProfileInterface>();
  userProfileData: MyProfileInterface;

  userIsCokiesAgreed = new Subject<number>();
  userIsCokiesAgreedData: number;

  countries = new Subject<Array<CountryClass>>();
  countriesData: Array<CountryClass>;

  blackListCountriesIndexes = new Subject<Array<number>>();
  blackListCountriesIndexesData: Array<number>;

  blackListCountriesIsoNames = new Subject<Array<string>>();
  blackListCountriesIsoNamesData: Array<string>;

  residenceCountry = new Subject<number>();
  residenceCountryData: number;

  citizenshipCountry = new Subject<number>();
  citizenshipCountryData: number;

  exchangePrice = new Subject<string>();
  exchangePriceData: string;

  exchangeAmount = new Subject<string>();
  exchangeAmountData: string;

  userCountry = new Subject<RestrictedCountriesClass>();
  userCountryData: RestrictedCountriesClass;

  userIsoCountry = new Subject<string>();
  userIsoCountryData: string;

  userCity = new Subject<string>();
  userCityData: string;

  isGeoCityIsInBlackList = new Subject<boolean>();
  isGeoCityIsInBlackListData: boolean;

  isResidenceCountryInBlackList = new Subject<boolean>();
  isResidenceCountryInBlackListData: boolean;

  isGeoCountryIsInBlackList = new Subject<boolean>();
  isGeoCountryIsInBlackListData: boolean;

  isGeoCountryIsInRestricktedList = new Subject<boolean>();
  isGeoCountryIsInRestricktedListData: boolean;

  isCitizenshipInBlackList = new Subject<boolean>();
  isCitizenshipInBlackListData: boolean;

  loginIncomeErrorStatus = new Subject<Array<string>>();
  loginIncomeErrorStatusData: Array<string>;

  isUserCompletedProfileForm = new Subject<boolean>();
  isUserCompletedProfileFormData: boolean;

  withdrawsAvailableLimit = new Subject<number>();
  withdrawsAvailableLimitData: number;

  messageTypeForAlertModal = new Subject<number>();
  messageTypeForAlertModalData: number;

  isLightTheme = new Subject<boolean>();
  isLightThemeData: boolean;

  messageTypeForGlobalMessage = new Subject<number>();
  messageTypeForGlobalMessageData: number;

  totalDepositUSDT = new Subject<number>();
  totalDepositUSDTData: number;

  isAuthorisedForFiat: boolean;
  isAuthorisedForFiatModal = new Subject<boolean>();

  isAuthorisedForStopLossTakeProfit: boolean;
  isAuthorisedForStopLossTakeProfitData = new Subject<boolean>();

  ticksHistory = new Subject<Array<TickHistoryModel>>();
  ticksHistoryData: Array<TickHistoryModel>;

  isVisibleProfileEmptyFields = new Subject<boolean>();
  isVisibleProfileEmptyFieldsData: boolean;

  arrayNotCompletedProfileFields = new Subject<Array<string>>();
  arrayNotCompletedProfileFieldsData: Array<string>;

  сurrencyLastPrice: CurrencyLastPriceClass;
  сurrencyLastPriceData = new Subject<CurrencyLastPriceClass>();

  walletToCheck = new Subject<string>();
  walletToCheckData: string;

  userBalances: Array<BalanceInterface>;

  private _lastMarketsDetails = new Map<string, any>();

  marketsDetails$ = concat(
    this.getMarketsList(),
    this.SubscribeToSummaryDeltas(),
  ).pipe(
    map(
      (markets) => markets.map(
        (v) => this._mergeMarketField(this._lastMarketsDetails.get(v.MarketName) || {}, v),
      )
    ),
    tap((markets) => this._lastMarketsDetails = new Map<string, any>(markets.map((v) => [v.MarketName, v]))),
  );

  constructor(
    public base: BaseService,
    private http: HttpClient,
    public router: Router
  ) {
    this.market = localStorage.getItem('bitPair') ? localStorage.getItem('bitPair') : 'BTC-ETH';
    this.SERVER_URL = environment.host;
    this.socket = socketIo(this.SERVER_URL);
    this.headers = new Headers({'Content-Type': 'application/jsonp'});
    this.setCurrentTheme(ThemesModel.DEFAULT);
  }
  private _mergeMarketField = (last: any, current: any) => {
    const mergedKeys = new Set([...Object.keys(last), ...Object.keys(current)]);
    return Array.from(mergedKeys.values()).reduce(
      (acc, k) => ({...acc, [k]: current[k] || last[k]}), {}
    );
  }
  public storePair(pair: string) {
    localStorage.setItem('bitPair', pair);
    this.market = pair;
  }
  public getPair(): string {
    return (this.market) ? this.market : (localStorage.getItem('bitPair') ? localStorage.getItem('bitPair') : '');
  }

  public joinRoom() {
    // console.log('joinedRoom ' + this.market);
    this.socket.emit('joinRoom', this.market);
  }
  public joinRoomUserId(id: string) {
    // console.log('joinedRoom ' + 'u_' + id);
    this.socket.emit('joinRoom', 'u_' + id);
  }

  public leaveRoom() {
    // console.log('leavedRoom ' + this.market);
    this.socket.emit('leaveRoom', this.market);
  }
  public leaveRoomUserId(id: string) {
    // console.log('leavedRoom ' + 'u_' + id);
    this.socket.emit('leaveRoom', 'u_' + id);
  }

  public SubscribeMarketHistory() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeMarketHistory', data => observer.next(data));
    });
  }

  public getTicker() {
    return new Observable<any>(observer => {
      this.socket.on('getTicker', data => observer.next(data));
    });
  }

  public SubscribeToExchangeDeltas() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeToExchangeDeltas', data => observer.next(data));
    });
  }

  public SubscribeToOrderbookUpdates() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeToOrderbookUpdates', data => observer.next(data));
    });
  }

  public EmitSubscribeToOrderbookUpdates() {
      this.socket.emit('subscribeToOrderbookUpdates', {marketSymbol: this.market});
  }

  public EmitUnsubscribeToOrderbookUpdates() {
      this.socket.emit('unsubscribeToOrderbookUpdates', {marketSymbol: this.market});
  }

  public SubscribeToTradeUpdates() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeToTradeUpdates', data => observer.next(data));
    });
  }

  public EmitSubscribeToTradeUpdates() {
    this.socket.emit('subscribeToTradeUpdates', {marketSymbol: this.market});
  }

  public EmitUnsubscribeToTradeUpdates() {
    this.socket.emit('unsubscribeToTradeUpdates', {marketSymbol: this.market});
  }

  public SubscribeOpenOrders() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeOpenOrders', data => observer.next(data));
    });
  }

  public SubscribeToSummaryDeltas() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeToSummaryDeltas', data => observer.next(data));
    });
  }

  public SubscribeToUserBalances() {
    return new Observable<Array<BalanceInterface>>(observer => {
      this.socket.on('SubscribeToUserBalanceUpdates', data => observer.next(data));
    });
  }

  public SubscribeToUpdateOrders() {
    return new Observable<any>(observer => {
      this.socket.on('ud', data => observer.next(data));
    });
  }
  public SubscribeToUpdateFavs() {
    return new Observable<any>(observer => {
      this.socket.on('uf', data => observer.next(data));
    });
  }
  public SubscribeToUpdateBalances() {
    return new Observable<any>(observer => {
      this.socket.on('ub', data => observer.next(data));
    });
  }

  public SubscribeToCandleUpdates(interval) {
    const pair = this.getPair();
    this.socket.emit('joinRoom', `${pair}_${interval}`);
    return new Observable<any>(observer => {
      this.socket.on('candleUpdates', (data) => observer.next(data));
    })
  }

  public emitCandleUpdates(data) {
    this.socket.emit('subscribeToCandleUpdates', data);
  }

    public emitUnsubscribeCandleUpdates(data) {
    this.socket.emit('unsubscribeToCandleUpdates', data);
  }

  public getMarketHistory(): Observable<any> {
    return this.base.get(`api/v1/bitt/market-history?market=${this.market}`);
  }

  public getMarketSummary(): Observable<any> {
    return this.base.get(`api/v1/bitt/market-summary?market=${this.market}`);
  }

  public getMarketPairsShortInfoServer(): Observable<any> {
    return this.base.get(`api/v1/bitt/markets-short-info?market=` + this.getPair());
  }

  public getOrderBookHistory(): Observable<any> {
    return this.base.get(`api/v1/bitt/orderbooks-history?market=${this.market}`);
  }

  public getDataByIP(ip: string): Observable<any> {
    return this.base.getJsonP('https://ipinfo.io/json');
  }

  public getTicksHistory(): Observable<any> {
    return this.base.get('api/v1/bitt/ticks-history');
  }
  public addFreeCoinsToUserWallet(id: number): Observable<any> {
    return this.base.post(`api/v1/user/spc-bonus/${id}`);
  }
  public setCookiesAgreementStatus(id: number, isAgreed: number): Observable<any> {
    return this.base.post(`api/v1/user/cookies_agreed/${id}/${isAgreed}`);
  }
  public getFavoriteMarkets(): Observable<any> {
    return this.base.get('api/v1/favorites/list');
  }

  public getCurrencyList(): Observable<any> {
    return this.base.get('api/v1/user/currencies/list');
  }
  public getCurrenciesListFromServer(): void {
    this.getCurrencyList()
      .subscribe((data: Array<CurrencyInterface>) => {
        this.passSharedCurrenciesList(data);
        this.setSharedCurrenciesList(data);
      });
  }
  public getUserProfileFromServer(from?: any) {
    // console.log(from || 'unknown');
    this.getUserProfile()
      .subscribe((res: MyProfileInterface) => {
        const userId = res.id;
        this.passUserId(userId);
        this.setUserId(userId);
        this.passUsersProfile(res);
        this.setUsersProfile(res);
        localStorage.setItem('visible-set', '' + userId);
        this.isUserCompletedProfileFormGetter(res);
      });
  }

  public getMarketMinOrderAmount() {
    this.getMarketPairsShortInfoServer()
      .subscribe(data => {
        const marketPairMinOrderAmount = data[this.getPair()];
        this.passMarketPairMinOrderAmount(marketPairMinOrderAmount);
        this.setMarketPairMinOrderAmount(marketPairMinOrderAmount);
      });
  }


  public addToFavoriteMarkets(data): Observable<any> {
    return this.base.post('api/v1/favorites', data);
  }
  public removeFromFavoriteMarkets(data): Observable<any> {
    return this.base.delete('api/v1/favorites', data);
  }

  public getBalances(): Observable<any> {
    return this.getWallets();
  }

  public buy(quantity, rate): Observable<any> {
    return this.base.post('api/v1/bitt/buy', {
      market: this.market,
      type: 'LIMIT',
      quantity,
      rate,
      timeInEffect: 'IMMEDIATE_OR_CANCEL',
      conditionType: 'NONE',
      target: 0
    });
  }

  public sendImagesKYC(type: string, document: any): Promise<any> {
    const input = new FormData();
    let url = '';
    switch (type) {
      case 'passport':
        url = '/kyc-passport'; break;
      case 'selfie':
        url = '/kyc-selfie'; break;
    }

    input.set('type', type);
    input.set('document', document);
    return this.base.postMultipartFormData(`api/v1/user${url}`, input);
  }

  // public sendPDFtoServer(url: string, body: any): Observable<any> {
  //   const headers: Headers = new Headers();
  //   this.token = localStorage.getItem('token');
  //
  //   headers.append('Content-Type', 'multipart/form-data');
  //   headers.append('Authorization', 'Bearer ' + this.token);
  //   // headers.append('Accept', 'application/json');
  //
  //   return this.http.post(url, body, {headers: headers})
  //     .map((res: Response) => <Object[]>res.json());
  // }

  public getWallets(): Observable<any> {
    return this.base.get('api/v1/user/wallets');
  }

  public getImagesKYC(): Observable<any> {
    return this.base.get('api/v1/user/kyc-urls');
  }

  public getDepositsWithdrawals(): Observable<any> {
    return this.base.get('api/v1/user/deposits-withdrawals');
  }

  public sell(quantity, rate): Observable<any> {
    return this.base.post('api/v1/bitt/sell', {
      market: this.market,
      type: 'LIMIT',
      quantity,
      rate,
      timeInEffect: 'IMMEDIATE_OR_CANCEL',
      conditionType: 'NONE',
      target: 0
    });
  }

  public updateOrderBookData(data) {
    this.orderBookHistory.next(data);
  }

  public getOrderBookData() {
    return this.orderBookHistory.asObservable();
  }

  public getTransactions(): Observable<any> {
    return this.base.get('api/v1/bitt/withdrawal-deposit');
  }

  public getWalletFeeCurrency(currency: string): Observable<any> {
    return this.base.get('api/v1/user/wallets/fee?currency=' + currency);
  }

  public getAllCurrenciesFees(): Observable<any> {
    return this.base.get('api/v1/user/wallets/all-currencies');
  }

  public getAllCurrencieHealth(): Observable<any> {
    return this.base.get('api/v1/bitt/currencies-health');
  }

  public getBlockedMarkets(): Observable<any> {
    return this.base.get('api/v1/settings/blocked-markets');
  }

  public getServerCountries(): Observable<any> {
    return this.base.get('api/v1/settings/countries');
  }

  public getServerBlackListCountries(): Observable<any> {
    return this.base.get('api/v1/settings/black-list-countries');
  }

  public getCountriesData(): void {
    this.getServerCountries()
      .subscribe(data => {
        this.passCountries(data);
        this.setCountries(data);
        this.findIsoNamesForBlackListCountries();
      });
  }
  public getBlackListCountriesData(): void {
    this.getServerBlackListCountries()
      .subscribe(data => {
        this.passBlackListCountriesIndexes(data);
        this.setBlackListCountriesIndexes(data);
        this.findIsoNamesForBlackListCountries();
      });
  }

  public findIsoNamesForBlackListCountries() {
    let countries = Array<CountryClass>(0);
    let blackListCountriesIsoNames = Array<string>(0);
    let blackListCountriesIndexes = Array<number>(0);
    countries = this.getCountries();
    blackListCountriesIndexes = this.getBlackListCountriesIndexes();
    if (countries && countries.length) {
      if (blackListCountriesIndexes && blackListCountriesIndexes.length) {
        blackListCountriesIsoNames = [];
        const blackList = this.filterIsoNamesForBlackListCountries(countries, blackListCountriesIndexes);
        blackList.forEach(country => blackListCountriesIsoNames.push(country.code));
        this.passBlackListCountriesIsoNames(blackListCountriesIsoNames);
        this.setBlackListCountriesIsoNames(blackListCountriesIsoNames);
      } else {
        // TODO get blackListCountriesIndexes
      }
    } else {
      // TODO get countries
      // this.getCountriesData();
    }


    this.getCountriesEmitter()
      .subscribe(response => {
        countries = response;
        if (countries && countries.length && blackListCountriesIndexes && blackListCountriesIndexes.length) {
          blackListCountriesIsoNames = [];
          const blackList = this.filterIsoNamesForBlackListCountries(countries, blackListCountriesIndexes);
          blackList.forEach(country => blackListCountriesIsoNames.push(country.code));
          this.passBlackListCountriesIsoNames(blackListCountriesIsoNames);
          this.setBlackListCountriesIsoNames(blackListCountriesIsoNames);
        }
      });
    this.getBlackListCountriesIndexesEmitter()
      .subscribe(response => {
        blackListCountriesIndexes = response;
        if (countries && countries.length && blackListCountriesIndexes && blackListCountriesIndexes.length) {
          blackListCountriesIsoNames = [];
          const blackList = this.filterIsoNamesForBlackListCountries(countries, blackListCountriesIndexes);
          blackList.forEach(country => blackListCountriesIsoNames.push(country.code));
          this.passBlackListCountriesIsoNames(blackListCountriesIsoNames);
          this.setBlackListCountriesIsoNames(blackListCountriesIsoNames);
        }
      });
  }

  public filterIsoNamesForBlackListCountries(countries: Array<CountryClass>,
                                             blackListCountriesIndexes: Array<number>): Array<CountryClass> {
    const result = countries.filter(country => {
      return blackListCountriesIndexes.includes(country.id);
    });

    return result;
  }

  public withdrawal(data): Observable<any> {
    // return this.base.post('api/v1/order/withdrawal', data);
    return this.base.post('api/v1/user/withdrawal', data);
  }

  public updateProfile(data): Observable<any> {
    return this.base.patch('api/v1/user/me', data);
  }

  public getMarketsList(): Observable<any> {
    return this.base.get('api/v1/settings/markets').pipe(
      map((data) => data.filter((v) => v.MarketName.split('-')[1] !== 'BCXG') || this.userProfileData.email === 'nurken13101976@gmail.com')
    );
  }

  public getFreeCoinsStatus(userId: number): Observable<any> {
    return this.base.get(`api/v1/user/spc-bonus/${userId}`);
  }

  public postRegistrationBonus(userId: number): Observable<any> {
    return this.base.post(`api/v1/user/reg-bonus/${userId}`);
  }

  public postDepositBonus(userId: number): Observable<any> {
    return this.base.post(`api/v1/user/dep-bonus/${userId}`);
  }

  public postKycBonus(userId: number): Observable<any> {
    return this.base.post(`api/v1/user/kyc-bonus/${userId}`);
  }
  public postUpdateWallets(userId: number): Observable<any> {
    return this.base.post(`api/v1/user/update-wallets/${userId}`);
  }
  public postUpdateWithdrawalStatus(userId: number): Observable<any> {
    return this.base.get(`api/v1/user/check-withdrawal-status/${userId}`);
  }
  public postCheckPendingWalletsStatus(userId: number): Observable<any> {
    return this.base.get(`api/v1/user/check-pending-wallets/${userId}`);
  }

  getUserProfile(): Observable<any> {
    return this.base.get('api/v1/user/me');
  }

  getUserKycStatus(): Observable<any> {
    return this.base.get('api/v1/user/kyc-status');
  }

  getUserLogs(): Observable<any> {
    return this.base.get('api/v1/user/logs');
  }
  getIpWhiteList(): Observable<any> {
    return this.base.get('api/v1/user/white-list');
  }
  addToIpWhiteList(data): Observable<any> {
    return this.base.post('api/v1/user/white-list', data);
  }
  removeFromIpWhiteList(data): Observable<any> {
    return this.base.delete('api/v1/user/white-list', data);
  }
  getWithdrawalWhiteList(): Observable<any> {
    return this.base.get('api/v1/user/withdrawal-white-list');
  }
  addToWithdrawalWhiteList(data): Observable<any> {
    return this.base.post('api/v1/user/withdrawal-white-list', data);
  }
  removeFromWithdrawalWhiteList(data): Observable<any> {
    return this.base.delete('api/v1/user/withdrawal-white-list', data);
  }
  // trade temporary
  getOrderHistory(showOtherPairs: string): Observable<any> {
    let path = 'api/v1/order/history?market=';
    if (showOtherPairs === 'no') {
      path += this.market;
    }
    return this.base.get(path);
  }
  getOpenOrders(showOtherPairs: string): Observable<any> {
    let path = 'api/v1/order/open?market=';
    if (showOtherPairs === 'no') {
      path += this.market;
    }
    return this.base.get(path);
  }
  getOpenStopLimitOrders(userId: number): Observable<any> {
    return of([]);
    // return this.base.get(`api/v1/order/getUserStopLimitOrders/${userId}`);
  }
  exchangeBuy(quantity: number, rate: number): Observable<any> {
    const data = {
      marketName: this.market,
      quantity: quantity,
      limit: rate
    };
    return this.base.post('api/v1/order/tradeBuy', data);
  }
  exchangeStopLimit(order: OrderStopLimitClass): Observable<any> {
    return this.base.post('api/v1/order/create-stop-limit', order);
  }
  exchangeSell(quantity: number, rate: number): Observable<any> {
    const data = {
      marketName: this.market,
      quantity: quantity,
      limit: rate
    };
    return this.base.post('api/v1/order/tradeSell', data);
  }
  cancelOpenOrder(order): Observable<any> {
    return this.base.delete('api/v1/order/cancel/' + order, {});
  }
  cancelOpenStopLimitOrder(orderId): Observable<any> {
    return this.base.delete(`api/v1/order/cancelStopLimitOrder/${orderId}`, {});
  }
  deleteStopLimitOrder(orderId, body): Observable<any> {
    return this.base.patch(`api/v1/order/updateStopLimitOrder/${orderId}`, body);
  }
  cancelWithdrawal(withdrawal): Observable<any> {
    // user/cancel-withdrawal/{withdrawalId}
    return this.base.delete(`api/v1/user/cancel-withdrawal/${withdrawal}`, {});
  }
  public getWallet(currency): Observable<any> {
    return this.base.post('api/v1/user/wallet', {currency});
  }
  public initiatePoli(data: FiatClass): Observable<any> {
    return this.base.post('api/v1/user/poli/initiateTransaction', data);
  }
  // public getCurrencyList(): Array<Object> {
  //   return [
  //     {name: 'BTC', fullName: 'Bitcoin'},
  //     {name: 'BCH', fullName: 'Bitcoin Cash'},
  //     {name: 'XRP', fullName: 'Ripple'},
  //     {name: 'ZCL', fullName: 'ZClassic'},
  //     {name: 'ADA', fullName: 'Cardano'},
  //     {name: 'ETH', fullName: 'Ethereum'},
  //     {name: 'ETC', fullName: 'Ethereum Classic'},
  //     {name: 'MFT', fullName: 'Mainframe'},
  //     {name: 'XVG', fullName: 'Verge'},
  //     {name: 'XLM', fullName: 'Stellar'},
  //     {name: 'XMR', fullName: 'Monero'},
  //     {name: 'UP', fullName: 'UpToken'},
  //     {name: 'CMCT', fullName: 'Crowd Machine'},
  //     {name: 'PAY', fullName: 'TenX'},
  //     {name: 'TRX', fullName: 'TRON'},
  //     {name: 'POLY', fullName: 'Polymath'},
  //     {name: 'SC', fullName: 'Siacoin'},
  //     {name: 'LTC', fullName: 'Litecoin'},
  //     {name: 'SWT', fullName: 'Swarm City'},
  //     {name: 'STRAT', fullName: 'Stratis'},
  //     {name: 'RDD', fullName: 'ReddCoin'},
  //     {name: 'REP', fullName: 'Augur'},
  //     {name: 'DASH', fullName: 'Dash'},
  //     {name: 'NLG', fullName: 'Gulden'},
  //     {name: 'RFR', fullName: 'Refereum'},
  //     {name: 'DOGE', fullName: 'Dogecoin'},
  //     {name: 'GBYTE', fullName: 'Byteball Bytes'},
  //     {name: 'ARDR', fullName: 'Ardor'},
  //   ].sort((a, b) => {
  //     if (a.name < b.name ) return -1;
  //     if (a.name > b.name ) return 1;
  //     return 0;
  //   });
  // }

  passSharedCurrenciesList(data: Array<CurrencyInterface>) {
    this.currenciesList.next(data);
  }
  setSharedCurrenciesList(data: Array<CurrencyInterface>) {
    this.currenciesListData = data;
  }
  getSharedCurrenciesList(): Array<CurrencyInterface> {
    return this.currenciesListData;
  }
  getSharedCurrenciesListEmitter(): Observable<Array<CurrencyInterface>> {
    return this.currenciesList.asObservable();
  }

  passMarketPairMinOrderAmount(data: MarketsShortInfoInterface) {
    this.marketPairMinOrderAmount.next(data);
  }
  setMarketPairMinOrderAmount(data: MarketsShortInfoInterface) {
    this.marketPairMinOrderAmountData = data;
  }
  getMarketPairMinOrderAmount(): MarketsShortInfoInterface {
    return this.marketPairMinOrderAmountData;
  }
  getMarketPairMinOrderAmountEmitter(): Observable<MarketsShortInfoInterface> {
    return this.marketPairMinOrderAmount.asObservable();
  }

  passMarketPairNew(data: string) {
    this.marketPairNew.next(data);
  }
  getMarketPairNewEmitter(): Observable<string> {
    return this.marketPairNew.asObservable();
  }

  passUserId(data: number) {
    this.userId.next(data);
  }
  setUserId(data: number) {
    this.userIdData = data;
  }
  getUserId(): number {
    return this.userIdData;
  }
  getUserIdEmitter(): Observable<number> {
    return this.userId.asObservable();
  }
  getUserIdCompleteEmitter() {
    this.userId && this.userId.complete();
  }

  passUsersProfile(data: MyProfileInterface) {
    this.userProfile.next(data);
  }
  setUsersProfile(data: MyProfileInterface) {
    this.userProfileData = data;
  }
  getUsersProfile(): MyProfileInterface {
    return this.userProfileData;
  }
  getUsersProfileEmitter(): Observable<MyProfileInterface> {
    return this.userProfile.asObservable();
  }
  getUsersProfileCompleteEmitter() {
    this.userProfile && this.userProfile.complete();
  }

  public passIsAuthorisedForFiatModal() {
    const userId = this.getUserId() ? this.getUserId() : +localStorage.getItem('visible-set');
    const isAuthorisedForFiat = (<Array<number>>this.usersAuthorisedForFiatModal).includes(+userId);
    this.isAuthorisedForFiat = isAuthorisedForFiat;
    this.isAuthorisedForFiatModal.next(isAuthorisedForFiat);
  }
  public getIsAuthorisedForFiatModal(): boolean {
    return this.isAuthorisedForFiat;
  }
  public getIsAuthorisedForFiatModalEmitter(): Observable<boolean> {
    return this.isAuthorisedForFiatModal.asObservable();
  }

  public passIsAuthorisedForStopLossTakeProfit(isAuthorised?: boolean) {
    let userId, isAuthorisedForStopLossTakeProfit;
    if (isAuthorised === true || isAuthorised === false) {
      isAuthorisedForStopLossTakeProfit = isAuthorised;
    } else {
      userId = this.getUserId() ? this.getUserId() : (+localStorage.getItem('visible-set') || 0);
      isAuthorisedForStopLossTakeProfit = (<Array<number>>this.usersAuthorisedForStopLossTakeProfit).includes(+userId);
    }
    isAuthorisedForStopLossTakeProfit = true; //  remove this line if you want to define authorised user
    this.isAuthorisedForStopLossTakeProfit = isAuthorisedForStopLossTakeProfit;
    this.isAuthorisedForStopLossTakeProfitData.next(isAuthorisedForStopLossTakeProfit);
  }
  public getIsAuthorisedForStopLossTakeProfit(): boolean {
    return this.isAuthorisedForStopLossTakeProfit;
  }
  public getIsAuthorisedForStopLossTakeProfitEmitter(): Observable<boolean> {
    return this.isAuthorisedForStopLossTakeProfitData.asObservable();
  }

  passUserIsCokiesAgreed(data: number) {
    this.userIsCokiesAgreed.next(data);
  }
  setUserIsCokiesAgreed(data: number) {
    this.userIsCokiesAgreedData = data;
  }
  getUserIsCokiesAgreed(): number {
    return this.userIsCokiesAgreedData;
  }
  getUserIsCokiesAgreedEmitter(): Observable<number> {
    return this.userIsCokiesAgreed.asObservable();
  }

  passCountries(data: Array<CountryClass>) {
    this.countries.next(data);
  }
  setCountries(data: Array<CountryClass>) {
    this.countriesData = data;
  }
  getCountries(): Array<CountryClass> {
    return this.countriesData;
  }
  getCountriesEmitter(): Observable<Array<CountryClass>> {
    return this.countries.asObservable();
  }

  passBlackListCountriesIndexes(data: Array<number>) {
    this.blackListCountriesIndexes.next(data);
  }
  setBlackListCountriesIndexes(data: Array<number>) {
    this.blackListCountriesIndexesData = data.sort((a, b) => {
          if (a < b ) return -1;
          if (a > b ) return 1;
        });
    // console.log('blackListCountriesIndexes', this.blackListCountriesIndexesData);
  }
  getBlackListCountriesIndexes(): Array<number> {
    return this.blackListCountriesIndexesData;
  }
  getBlackListCountriesIndexesEmitter(): Observable<Array<number>> {
    return this.blackListCountriesIndexes.asObservable();
  }

  passBlackListCountriesIsoNames(data: Array<string>) {
    this.blackListCountriesIsoNames.next(data);
  }
  setBlackListCountriesIsoNames(data: Array<string>) {
    this.blackListCountriesIsoNamesData = data.sort((a, b) => {
          if (a < b ) return -1;
          if (a > b ) return 1;
        });
  }
  getBlackListCountriesIsoNames(): Array<string> {
    return this.blackListCountriesIsoNamesData;
  }
  getBlackListCountriesIsoNamesEmitter(): Observable<Array<string>> {
    return this.blackListCountriesIsoNames.asObservable();
  }

  passResidenceCountry(data: number) {
    this.residenceCountry.next(data);
  }
  setResidenceCountry(data: number) {
    this.residenceCountryData = data;
  }
  getResidenceCountry(): number {
    return this.residenceCountryData;
  }
  getResidenceCountryEmitter(): Observable<number> {
    return this.residenceCountry.asObservable();
  }

  passCitizenshipCountry(data: number) {
    this.citizenshipCountry.next(data);
  }
  setCitizenshipCountry(data: number) {
    this.citizenshipCountryData = data;
  }
  getCitizenshipCountry(): number {
    return this.citizenshipCountryData;
  }
  getCitizenshipCountryEmitter(): Observable<number> {
    return this.citizenshipCountry.asObservable();
  }

  passExchangeAmount(data: string) {
    this.exchangeAmount.next(data);
  }
  setExchangeAmount(data: string) {
    this.exchangeAmountData = data;
  }
  getExchangeAmount(): string {
    return this.exchangeAmountData;
  }
  getExchangeAmountEmitter(): Observable<string> {
    return this.exchangeAmount.asObservable();
  }

  passUserCountry(data: RestrictedCountriesClass) {
    this.userCountry.next(data);
  }
  setUserCountry(data: RestrictedCountriesClass) {
    this.userCountryData = data;
  }
  getUserCountry(): RestrictedCountriesClass {
    return this.userCountryData;
  }
  getUserCountryEmitter(): Observable<RestrictedCountriesClass> {
    return this.userCountry.asObservable();
  }

  passUserIsoCountry(data: string) {
    this.userIsoCountry.next(data);
  }
  setUserIsoCountry(data: string) {
    this.userIsoCountryData = data;
  }
  getUserIsoCountry(): string {
    return this.userIsoCountryData;
  }
  getUserIsoCountryEmitter(): Observable<string> {
    return this.userIsoCountry.asObservable();
  }
  getUserIsoCountryCompleteEmitter() {
    this.userIsoCountry && this.userIsoCountry.complete();
  }

  passUserCity(data: string) {
    this.userCity.next(data);
  }
  setUserCity(data: string) {
    this.userCityData = data;
  }
  getUserCity(): string {
    return this.userCityData;
  }
  getUserCityEmitter(): Observable<string> {
    return this.userCity.asObservable();
  }
  getUserCityCompleteEmitter() {
    this.userCity && this.userCity.complete();
  }

  passExchangePrice(data: string) {
    this.exchangePrice.next(data);
  }
  setExchangePrice(data: string) {
    this.exchangePriceData = data;
  }
  getExchangePrice(): string {
    return this.exchangePriceData;
  }
  getExchangePriceEmitter(): Observable<string> {
    return this.exchangePrice.asObservable();
  }

  passIsResidenceCountryInBlackList(data: boolean) {
    this.isResidenceCountryInBlackList.next(data);
  }
  setIsResidenceCountryInBlackList(data: boolean) {
    this.isResidenceCountryInBlackListData = data;
  }
  getIsResidenceCountryInBlackList(): boolean {
    return this.isResidenceCountryInBlackListData;
  }
  getIsResidenceCountryInBlackListEmitter(): Observable<boolean> {
    return this.isResidenceCountryInBlackList.asObservable();
  }

  passIsGeoCountryIsInBlackList(data: boolean) {
    this.isGeoCountryIsInBlackList.next(data);
  }
  setIsGeoCountryIsInBlackList(data: boolean) {
    this.isGeoCountryIsInBlackListData = data;
  }
  getIsGeoCountryIsInBlackList(): boolean {
    return this.isGeoCountryIsInBlackListData;
  }
  getIsGeoCountryIsInBlackListEmitter(): Observable<boolean> {
    return this.isGeoCountryIsInBlackList.asObservable();
  }

  passIsGeoCountryIsInRestricktedList(data: boolean) {
    this.isGeoCountryIsInRestricktedList.next(data);
  }
  setIsGeoCountryIsInRestricktedList(data: boolean) {
    this.isGeoCountryIsInRestricktedListData = data;
  }
  getIsGeoCountryIsInRestricktedList(): boolean {
    return this.isGeoCountryIsInRestricktedListData;
  }
  getIsGeoCountryIsInRestricktedListEmitter(): Observable<boolean> {
    return this.isGeoCountryIsInRestricktedList.asObservable();
  }

  passIsCitizenshipInBlackList(data: boolean) {
    this.isCitizenshipInBlackList.next(data);
  }
  setIsCitizenshipInBlackList(data: boolean) {
    this.isCitizenshipInBlackListData = data;
  }
  getIsCitizenshipInBlackList(): boolean {
    return this.isCitizenshipInBlackListData;
  }
  getIsCitizenshipInBlackListEmitter(): Observable<boolean> {
    return this.isCitizenshipInBlackList.asObservable();
  }

  passLoginIncomeErrorStatus(data: Array<string>) {
    this.loginIncomeErrorStatus.next(data);
  }
  setLoginIncomeErrorStatus(data: Array<string>) {
    this.loginIncomeErrorStatusData = data;
  }
  getLoginIncomeErrorStatus(): Array<string> {
    return this.loginIncomeErrorStatusData;
  }
  getLoginIncomeErrorStatusEmitter(): Observable<Array<string>> {
    return this.loginIncomeErrorStatus.asObservable();
  }

  passIsUserCompletedProfileForm(data: boolean) {
    this.isUserCompletedProfileForm.next(data);
  }
  setIsUserCompletedProfileForm(data: boolean) {
    this.isUserCompletedProfileFormData = data;
  }
  getIsUserCompletedProfileForm(): boolean {
    return this.isUserCompletedProfileFormData;
  }
  getIsUserCompletedProfileFormEmitter(): Observable<boolean> {
    return this.isUserCompletedProfileForm.asObservable();
  }

  passWithdrawsAvailableLimit(data: number) {
    this.withdrawsAvailableLimit.next(data);
  }
  setWithdrawsAvailableLimit(data: number) {
    this.withdrawsAvailableLimitData = data;
  }
  getWithdrawsAvailableLimit(): number {
    return this.withdrawsAvailableLimitData;
  }
  getWithdrawsAvailableLimitEmitter(): Observable<number> {
    return this.withdrawsAvailableLimit.asObservable();
  }

  passMessageTypeForAlertModal(data: number) {
    this.messageTypeForAlertModal.next(data);
  }
  setMessageTypeForAlertModal(data: number) {
    this.messageTypeForAlertModalData = data;
  }
  getMessageTypeForAlertModal(): number {
    return this.messageTypeForAlertModalData;
  }
  getMessageTypeForAlertModalEmitter(): Observable<number> {
    return this.messageTypeForAlertModal.asObservable();
  }

  passIsLightTheme(data: boolean) {
    this.isLightTheme.next(data);
  }

  setIsLightTheme(data: boolean) {
    this.isLightThemeData = data;
  }

  getIsLightTheme(): boolean {
    return this.isLightThemeData;
  }

  getIsLightThemeEmitter(): Observable<boolean> {
    return this.isLightTheme.asObservable();
  }

  setCurrentTheme(theme) {
    const currentTheme = localStorage.getItem('currentTheme');
    if (currentTheme) {
      this.currentTheme = currentTheme;
    } else {
      this.currentTheme = theme;
    }
  }

  passMessageTypeForGlobalMessage(data: number) {
    this.messageTypeForGlobalMessage.next(data);
  }
  setMessageTypeForGlobalMessage(data: number) {
    this.messageTypeForGlobalMessageData = data;
  }
  getMessageTypeForGlobalMessage(): number {
    return this.messageTypeForGlobalMessageData;
  }
  getMessageTypeGlobalMessageEmitter(): Observable<number> {
    return this.messageTypeForGlobalMessage.asObservable();
  }

  passTotalDepositUSDT(data: number) {
    this.totalDepositUSDT.next(data);
  }
  setTotalDepositUSDT(data: number) {
    this.totalDepositUSDTData = data;
  }
  getTotalDepositUSDT(): number {
    return this.totalDepositUSDTData;
  }
  getTotalDepositUSDTEmitter(): Observable<number> {
    return this.totalDepositUSDT.asObservable();
  }

  passTickHistory(data: Array<TickHistoryModel>) {
    this.ticksHistory.next(data);
  }
  setTickHistory(data: Array<TickHistoryModel>) {
    this.ticksHistoryData = data;
  }
  getTickHistory(): Array<TickHistoryModel> {
    return this.ticksHistoryData;
  }
  getTickHistoryEmitter(): Observable<Array<TickHistoryModel>> {
    return this.ticksHistory.asObservable();
  }

  passIsVisibleProfileEmptyFields(data: boolean) {
    this.isVisibleProfileEmptyFields.next(data);
  }
  setIsVisibleProfileEmptyFields(data: boolean) {
    this.isVisibleProfileEmptyFieldsData = data;
  }
  getIsVisibleProfileEmptyFields(): boolean {
    return this.isVisibleProfileEmptyFieldsData;
  }
  getIsVisibleProfileEmptyFieldsEmitter(): Observable<boolean> {
    return this.isVisibleProfileEmptyFields.asObservable();
  }

  passArrayNotCompletedProfileFields(data: Array<string>) {
    this.arrayNotCompletedProfileFields.next(data);
  }
  setArrayNotCompletedProfileFields(data: Array<string>) {
    this.arrayNotCompletedProfileFieldsData = data;
  }
  getArrayNotCompletedProfileFields(): Array<string> {
    return this.arrayNotCompletedProfileFieldsData;
  }
  getArrayNotCompletedProfileFieldsEmitter(): Observable<Array<string>> {
    return this.arrayNotCompletedProfileFields.asObservable();
  }

  setСurrencyLastPrice(data: CurrencyLastPriceClass) {
    this.сurrencyLastPrice = data;
  }
  passСurrencyLastPrice(data: CurrencyLastPriceClass) {
    this.сurrencyLastPriceData.next(data);
  }
  getСurrencyLastPrice(): CurrencyLastPriceClass {
    return this.сurrencyLastPrice;
  }
  getСurrencyLastPriceEmitter(): Observable<CurrencyLastPriceClass> {
    return this.сurrencyLastPriceData.asObservable();
  }

  getLastUserBalance(): Array<BalanceInterface> {
    return this.userBalances;
  }
  setUserBalance(data: Array<BalanceInterface>) {
    this.userBalances = data;
  }

  public convertExpToNumber(_value: number): string {
    const value = '' + _value;
    const newValue = ((value.indexOf('e') >= 0) || (value.indexOf('E') >= 0)) ? this.convertExpToString(_value) : value;

    const newValueArr = newValue.split('.');
    let decimal = newValueArr[1] ? newValueArr[1] : '00000000';

    if (newValueArr && newValueArr[1] && newValueArr[1].length) {
      switch (newValueArr[1].length) {
        case 1: decimal = newValueArr[1] + '0000000'; break;
        case 2: decimal = newValueArr[1] + '000000'; break;
        case 3: decimal = newValueArr[1] + '00000'; break;
        case 4: decimal = newValueArr[1] + '0000'; break;
        case 5: decimal = newValueArr[1] + '000'; break;
        case 6: decimal = newValueArr[1] + '00'; break;
        case 7: decimal = newValueArr[1] + '0'; break;
      }
    } else {
      // console.log(_value);
    }

    return newValueArr[0] + '.' + decimal;
  }

  public removeCountryfromIsoNames(countryIso: string, countries: Array<string>): Array<string> {
    if (countries && countries.length) {
      return countries.filter(country => country !== countryIso);
    } else {
      return countries;
    }
  }
  public doVerifyIfGeoCountryIsInBlackListAndInRestricktedList(country: string) {
    let blackListCountriesIsoNames = this.getBlackListCountriesIsoNames();
    blackListCountriesIsoNames = this.removeCountryfromIsoNames('RU', blackListCountriesIsoNames);
    blackListCountriesIsoNames = this.removeCountryfromIsoNames('UA', blackListCountriesIsoNames);
    // console.log('blackListCountriesIsoNames 11', blackListCountriesIsoNames);
    if (blackListCountriesIsoNames && blackListCountriesIsoNames.length) {
      // console.log(444777);
      const isGeoCountryIsInBlackList = blackListCountriesIsoNames.some(blackListedCountry => blackListedCountry === country);
      const isGeoCountryIsInRestricktedList = this.restrictedCounries.some(restrictedCounry => restrictedCounry.ISO === country);
      this.passIsGeoCountryIsInBlackList(isGeoCountryIsInBlackList);
      this.setIsGeoCountryIsInBlackList(isGeoCountryIsInBlackList);
      this.passIsGeoCountryIsInRestricktedList(isGeoCountryIsInRestricktedList);
      this.setIsGeoCountryIsInRestricktedList(isGeoCountryIsInRestricktedList);
    } else {
      // this.getBlackListCountriesData();
    }
    this.getBlackListCountriesIsoNamesEmitter()
      .subscribe(response => {
        blackListCountriesIsoNames = response;
        blackListCountriesIsoNames = this.removeCountryfromIsoNames('RU', blackListCountriesIsoNames);
        blackListCountriesIsoNames = this.removeCountryfromIsoNames('UA', blackListCountriesIsoNames);
        // console.log('blackListCountriesIsoNames 22', blackListCountriesIsoNames);
        const isGeoCountryIsInBlackList = (blackListCountriesIsoNames && blackListCountriesIsoNames.length)
          ? blackListCountriesIsoNames.some(blackListedCountry => blackListedCountry === country)
          : true;
        const isGeoCountryIsInRestricktedList = this.restrictedCounries.some(restrictedCounry => restrictedCounry.ISO === country);
        this.passIsGeoCountryIsInBlackList(isGeoCountryIsInBlackList);
        this.setIsGeoCountryIsInBlackList(isGeoCountryIsInBlackList);
        this.passIsGeoCountryIsInRestricktedList(isGeoCountryIsInRestricktedList);
        this.setIsGeoCountryIsInRestricktedList(isGeoCountryIsInRestricktedList);
      });
  }
  public doVerifyIfResidenceCountryIsInBlackList(country: number): boolean {
    let blackListCountries = this.getBlackListCountriesIndexes();
    if (blackListCountries && blackListCountries.length) {
      const isResidenceCountryInBlackList = blackListCountries.some(blackListedCountry => blackListedCountry === country);
      this.passIsResidenceCountryInBlackList(isResidenceCountryInBlackList);
      this.setIsResidenceCountryInBlackList(isResidenceCountryInBlackList);
      // console.log('isResidenceCountryInBlackList 10', isResidenceCountryInBlackList);
      return isResidenceCountryInBlackList;
    } else {
      // this.getBlackListCountriesData();
    }
    this.getBlackListCountriesIndexesEmitter()
      .subscribe(response => {
        blackListCountries = response;
        // console.log('blackListCountries', blackListCountries);
        const isResidenceCountryInBlackList = (blackListCountries && blackListCountries.length)
          ? blackListCountries.some(blackListedCountry => blackListedCountry === country)
          : true;
        this.passIsResidenceCountryInBlackList(isResidenceCountryInBlackList);
        this.setIsResidenceCountryInBlackList(isResidenceCountryInBlackList);
        // console.log('isResidenceCountryInBlackList 11', isResidenceCountryInBlackList);
        return isResidenceCountryInBlackList;
      });
  }
  public doVerifyIfCitizenshipIsInBlackList(country: number): boolean {
    let blackListCountries = this.getBlackListCountriesIndexes();
    if (blackListCountries && blackListCountries.length) {
      const isCitizenshipInBlackList = blackListCountries.some(blackListedCountry => blackListedCountry === country);
      this.passIsCitizenshipInBlackList(isCitizenshipInBlackList);
      this.setIsCitizenshipInBlackList(isCitizenshipInBlackList);
      // console.log('isCitizenshipInBlackList 10', isCitizenshipInBlackList);
      return isCitizenshipInBlackList;
    } else {
      // this.getBlackListCountriesData();
    }
    this.getBlackListCountriesIndexesEmitter()
      .subscribe(response => {
        blackListCountries = response;
        // console.log('blackListCountries', blackListCountries);
        const isCitizenshipInBlackList = (blackListCountries && blackListCountries.length)
          ? blackListCountries.some(blackListedCountry => blackListedCountry === country)
          : true;
        this.passIsCitizenshipInBlackList(isCitizenshipInBlackList);
        this.setIsCitizenshipInBlackList(isCitizenshipInBlackList);
        // console.log('isCitizenshipInBlackList 11', isCitizenshipInBlackList);
        return isCitizenshipInBlackList;
      });
  }

  public doVerifyIfGeoCityIsInBlackList(city: string): boolean {
    return this.restrictedCities.some(blackListedCity => blackListedCity === city);
  }

  public convertExpToString(expNumber: number): string {
    const data = String(expNumber).split(/[eE]/);
    if (data.length === 1) {
      return data[0];
    }
    let z = '';
    const sign = expNumber < 0 ? '-' : '';
    const str = data[0].replace('.', '');
    let mag = Number(data[1]) + 1;

    if (mag < 0) {
      z = sign + '0.';
      while (mag++) {
        z += '0';
      }
      return z + str.replace(/^\-/, '');
    }
    mag -= str.length;
    while (mag--) {
      z += '0';
    }
    return str + z;
  }

  public findUserCountry() {
      // this.http.get('https://api.ipify.org?format=json').subscribe(dataip => {
        // console.log(dataip);
        // const publicIP = dataip['ip'];
        // console.log('publicIP', this.publicIP + ' ==>');

    if (this.isGEOturnedON && !this.getUserIsoCountry()) {
      this.getDataByIP('')
        .subscribe(result => {
          // console.log(result);
          const userIsoCountry = result.country ? result.country : '';
          const userCity = result.city ? result.city : '';
          const url = this.router.url ? this.router.url : this.router.url.slice(0, this.router.url.lastIndexOf('/'));
          // console.log(url);
          const tempCountries = this.restrictedCounries.filter(country => country.ISO === userIsoCountry);
          // console.log(tempCountries);
          const userCountry = (tempCountries && tempCountries.length === 1) ? tempCountries[0] : undefined;
          this.passUserCountry(userCountry);
          this.setUserCountry(userCountry);
          this.passUserIsoCountry(userIsoCountry);
          this.setUserIsoCountry(userIsoCountry);

          this.passUserCity(userCity);
          this.setUserCity(userCity);

          // console.log(userCity);

          if (this.reCaptcha_version === 'prod') {
            if (userIsoCountry && userIsoCountry === 'AU') {
              window.location.replace('https://trade.biteeu.com.au' + url);
            }
          }
          if (this.reCaptcha_version === 'auprod') {
            if (userIsoCountry && userIsoCountry !== 'AU') {
              // window.location.replace('https://trade.biteeu.com' + url);
            }
          }
        }, error1 => {
          console.log(error1);
        });
    }

      // });
  }

  public isUserCompletedProfileFormGetter(res: MyProfileInterface) {
    // this.getUserProfile()
    //   .subscribe((res: MyProfileInterface) => {
        const myProfileFormValue = res;
        const keysProfileForm = Object.keys(myProfileFormValue);
        // console.log(keysProfileForm);
        const notCompletedFieldsArray = [];
        keysProfileForm.forEach(key => {
          if (key !== 'phone'
            && key !== '2fa_enabled'
            && key !== 'email_verification_enabled'
            && key !== 'is_GDPR'
            && key !== 'withdrawals_mail_enable'
            && key !== 'cookies_agreed'
          ) {
            if (key === 'birthday') {
              if (myProfileFormValue[key] && myProfileFormValue[key].length < 10) {notCompletedFieldsArray.push(key); }
            } else {
              if (!myProfileFormValue[key] || !('' + myProfileFormValue[key]).length) {
                notCompletedFieldsArray.push(key);
              }
            }
          }
        });
        const isUserCompletedProfileForm = notCompletedFieldsArray && notCompletedFieldsArray.length === 0;
        // console.log('isUserCompletedProfileForm', isUserCompletedProfileForm);
        this.passIsUserCompletedProfileForm(isUserCompletedProfileForm);
        this.setIsUserCompletedProfileForm(isUserCompletedProfileForm);

        if (notCompletedFieldsArray.length > 0) {
          this.passArrayNotCompletedProfileFields(notCompletedFieldsArray);
          this.setArrayNotCompletedProfileFields(notCompletedFieldsArray);
        }
        if (res.cookies_agreed === 0 || res.cookies_agreed === 1) {
          this.passUserIsCokiesAgreed(res.cookies_agreed);
          this.setUserIsCokiesAgreed(res.cookies_agreed);
        }
      // });
  }

  public calculateWithdrawsAvailableLimit(totalBalance: number, totalLimit: number) {
    const withdrawsAvailableLimit = totalLimit - totalBalance;
    // console.log(withdrawsAvailableLimit);
    this.passWithdrawsAvailableLimit(withdrawsAvailableLimit);
    this.setWithdrawsAvailableLimit(withdrawsAvailableLimit);

  }

  public getEmailLowercase(data: string): string {
    const login = data.split('@')[0];
    const host = data.split('@')[1];
    return login.toLocaleLowerCase() + '@' + host;
  }

  getSumsubAccessToken(userId, ttlInSecs): Observable<any> {
    return this.base.get(`api/v1/sumsub/access-token?userId=${userId}&ttlInSecs=${ttlInSecs}`);
  }

  passWalletToCheck(data: string) {
    this.walletToCheck.next(data);
  }
  setWalletToCheck(data: string) {
    this.walletToCheckData = data;
  }
  getWalletToCheck(): string {
    return this.walletToCheckData;
  }
  getWalletToCheckEmitter(): Observable<string> {
    return this.walletToCheck.asObservable();
  }

  getMyRedemptions(): Observable<ReadonlyArray<BCXGRedemption>> {
    return this.base.get(`api/v1/bcxg/my-redemptions`);
  }

  cancelRedemptionRequestByUser(redemptionId: number): Observable<any> {
    return this.base.post(`api/v1/bcxg/cancel-redemption-by-user`, { redemptionId });
  }

  getGoldPricePerOunceUSD(): Observable<number> {
    return this.base.get(`api/v1/bcxg/gold-price-per-ounce-usd`);
  }

  getAccountBCXGValue(): Observable<number> {
    return this.base.get(`api/v1/bcxg/account-bcxg`);
  }

  createRedemption(bcxgRedemption: BCXGRedemption): Observable<any> {
    return this.base.post(`api/v1/bcxg/create-redemption`, bcxgRedemption);
  }

}
