import {Component} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-buy-crypto',
  templateUrl: './buy-crypto.component.html',
  styleUrls: ['./buy-crypto.component.scss']
})
export class BuyCryptoComponent {
  public isLightTheme = false;
  public buyCryptoForm: FormGroup;
  public paymentMethod: null | 'advcash' | 'mercuryo' = null;
  destroySubject$: Subject<void> = new Subject();

  constructor(public router: Router,
              private fb: FormBuilder,
              public dataService: DataService,
    ) {
      this.createForm();
      if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
      this.dataService.getIsLightThemeEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(isLightTheme => {
          this.isLightTheme = isLightTheme;
        });
    }

  private createForm(): void {
    this.buyCryptoForm = this.fb.group({
      ac_currency: ['USD', Validators.required],
      payment_option: [Validators.required],
    }, null);
  }

  public login(): void {
    const data = this.buyCryptoForm.value.ac_currency;
    localStorage.setItem('selected_currency', data);
    localStorage.setItem('selected_payment', this.paymentMethod);
    this.router.navigate(['/login']).then();
  }

  public setPaymentMethod(payment: 'advcash' | 'mercuryo'): void {
    this.paymentMethod = payment;
  }
}
