import {Component, OnDestroy} from '@angular/core';
import {SvgService} from '../../services/svg.service';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '../../services/data.service';
import {Subject} from 'rxjs/internal/Subject';
import { en, ru, tu } from './footer-links-data';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {
  public isLightTheme = false;
  public userIsCokiesAgreed: number;
  public isClosedCookieBar: boolean;
  destroySubject$: Subject<void> = new Subject();
  public usefulLinks = en.footer.usefulLinks;
  public socialLinks = en.footer.socials;
  public extraInfo = en.footer.extra;
  public currentLang: string;
  constructor(public svgService: SvgService,
              public dataService: DataService,
              private translateService: TranslateService) {
    this.getUserIsCokiesAgreedData();
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  ngOnInit() {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = this.translateService.currentLang;
      switch (this.currentLang) {
        case "en":
          this.usefulLinks = en.footer.usefulLinks;
          this.socialLinks = en.footer.socials;
          this.extraInfo = en.footer.extra;
          break;
        case "ru":
          this.usefulLinks = ru.footer.usefulLinks;
          this.socialLinks = ru.footer.socials;
          this.extraInfo = ru.footer.extra;
          break;
        case "tr":
          this.usefulLinks = tu.footer.usefulLinks;
          this.socialLinks = tu.footer.socials;
          this.extraInfo = tu.footer.extra;
          break;
        default:
          this.usefulLinks = en.footer.usefulLinks;
          this.socialLinks = en.footer.socials;
          this.extraInfo = en.footer.extra;
          break;
      }
    });
  }

  public getUserIsCokiesAgreedData() {
    this.userIsCokiesAgreed = this.dataService.getUserIsCokiesAgreed();
    if (this.userIsCokiesAgreed === 0) {
      this.isClosedCookieBar = false;
    }
    this.dataService.getUserIsCokiesAgreedEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(response => {
        this.userIsCokiesAgreed = response;
        if (this.userIsCokiesAgreed === 0) {
          this.isClosedCookieBar = false;
        }
      });
  }

  public getCurrentYear(): number {
    return new Date().getFullYear();
  }
}
