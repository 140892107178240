export interface OrderbooksMarketInterface {
  Size: number;
  Price: number;
  Total: number;
  Sum: number;
}

export class OrderbooksMarketModel implements OrderbooksMarketInterface {
  Size = undefined;
  Price = undefined;
  Total = undefined;
  Sum = undefined;

  constructor(data?: OrderbooksMarketInterface) {
    Object.assign(this, data);
  }
}

export interface OrderbooksHistoryMarketInterface {
  bid: Array<OrderbooksMarketModel>;
  // buy: Array<OrderbooksMarketModel>;
  ask: Array<OrderbooksMarketModel>;
  // sell: Array<OrderbooksMarketModel>;
}

export class OrderbooksHistoryMarketModel implements OrderbooksHistoryMarketInterface {
  // buy = [];
  // sell = [];
  bid = [];
  ask = [];

  constructor(data?: OrderbooksHistoryMarketInterface) {
    Object.assign(this, data);
  }
}
