import { Component, HostBinding, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
        height: '5rem', // initial height
        overflow: 'hidden',
      })),
      state('expanded', style({
        height: '*', // auto height
        overflow: 'hidden',
      })),
      transition('collapsed <=> expanded', [
        animate('0.3s ease-in-out')
      ])
    ])
  ]
})
export class NoticeComponent {
  isOpen = false;

  get state() {
    return this.isOpen ? 'expanded' : 'collapsed';
  }

  toggleNotice() {
    this.isOpen = !this.isOpen;
  }

}
