import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfileComponent} from './pages/profile/profile.component';
import {WalletsComponent} from './pages/profile/wallets/wallets.component';
import {NotificationsComponent} from './pages/profile/notifications/notifications.component';
import {WithdrawalWhitelistComponent} from './pages/profile/withdrawal-whitelist/withdrawal-whitelist.component';
import {IpWhitelistComponent} from './pages/profile/ip-whitelist/ip-whitelist.component';
import {TwoFactorAuthComponent} from './pages/profile/two-factor-auth/two-factor-auth.component';
import {PasswordComponent} from './pages/profile/password/password.component';
import {EnableAccountComponent} from './pages/profile/enable-account/enable-account.component';
import {MyActivityComponent} from './pages/profile/my-activity/my-activity.component';
import {MyProfileComponent} from './pages/profile/my-profile/my-profile.component';
import {IdentityVerificationComponent} from './pages/profile/identity-verification/identity-verification.component';
import {MarketsComponent} from './pages/markets/markets.component';
import {SearchComponent} from './pages/search/search.component';
import {AuthPageComponent} from './pages/auth-page/auth-page.component';
import {AuthGuard} from './services/auth.guard';
import {DataService} from './services/data.service';
import {KycPageComponent} from './pages/kyc-page/kyc-page.component';
import {SetLandingLanguageComponent} from './pages/landing/set-landing-language/set-landing-language.component';
import {FeesAndLimitsComponent} from './pages/fees-and-limits/fees-and-limits.component';
import {PublicApiComponent} from './pages/public-api/public-api.component';
import {BcxgComponent} from './pages/profile/bcxg/bcxg.component';
import {AdvcashComponent} from './pages/profile/advcash/advcash.component';
import {BuyCryptoComponent} from './pages/buy-crypto/buy-crypto/buy-crypto.component';
import {WalletStatus} from './pages/wallet-status/wallet-status.component';
import {RedirectGuard} from './services/redirectGuard';
import { MarketUnavailableComponent } from './pages/search/market-unavailable/market-unavailable.component';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/markets'},
  {path: 'markets', component: MarketUnavailableComponent},
  {path: '**', redirectTo: '/markets'},

  // {path: 'fees-and-limits', component: FeesAndLimitsComponent},
  // {path: 'wallet-status', component: WalletStatus},
  // {path: 'public-api', component: PublicApiComponent},
  // {path: 'sign-up/:lang', component: SetLandingLanguageComponent},
  // {path: 'search/:lang', component: SetLandingLanguageComponent},
  // {path: 'login/:lang', component: SetLandingLanguageComponent},
  // {path: 'login', component: AuthPageComponent},
  // {path: 'sign-up', component: AuthPageComponent},
  // {path: 'markets', component: SearchComponent},
  // {path: 'restore-password', component: AuthPageComponent},
  // {path: 'restore-password/:token', component: AuthPageComponent},
  // {path: 'change-password/:token', component: AuthPageComponent},
  // {path: 'login-verify', component: AuthPageComponent},
  // {path: 'login-verify/:token', component: AuthPageComponent},
  // {path: 'validate-email/:token', component: AuthPageComponent},
  // {path: 'payment/success', component: AuthPageComponent},
  // {path: 'payment/failure', component: AuthPageComponent},
  // {path: 'payment/cancellation', component: AuthPageComponent},
  // {path: 'payment/notification', component: AuthPageComponent},
  // {path: 'payment/receipt-unverified', component: AuthPageComponent},
  // {path: 'user-kyc/approve/:token', component: KycPageComponent},
  // {path: 'user-kyc/reject/:token', component: KycPageComponent},
  // {path: 'user-kyc/block/:token', component: KycPageComponent},
  // {path: 'trades/:pair', component: MarketsComponent},
  // {path: 'buy-crypto', component: BuyCryptoComponent},
  // {
  //   path: 'about',
  //   canActivate: [RedirectGuard],
  //   component: RedirectGuard,
  //   data: {
  //     externalUrl: 'https://www.biteeu.com/about'
  //   }
  // },
  // {
  //   path: 'docs/ippolicy',
  //   canActivate: [RedirectGuard],
  //   component: RedirectGuard,
  //   data: {
  //     externalUrl: 'https://www.biteeu.com/docs/BITEEU IP Address  and Cookie Policy SITE.pdf'
  //   }
  // },
  // {
  //   path: 'docs/privacyestonia',
  //   canActivate: [RedirectGuard],
  //   component: RedirectGuard,
  //   data: {
  //     externalUrl: 'https://www.biteeu.com/docs/Biteeu privacy Estonia SITE.pdf'
  //   }
  // },
  // {
  //   path: 'docs/termsofuseestonia',
  //   canActivate: [RedirectGuard],
  //   component: RedirectGuard,
  //   data: {
  //     externalUrl: 'https://www.biteeu.com/docs/Terms of Use Estonia SITE.pdf'
  //   }
  // },
  // {
  //   path: 'faq',
  //   canActivate: [RedirectGuard],
  //   component: RedirectGuard,
  //   data: {
  //     externalUrl: 'https://www.biteeu.com/faq'
  //   }
  // },


  // {
  //   path: 'twitter',
  //   canActivate: [RedirectGuard],
  //   component: RedirectGuard,
  //   data: {
  //     externalUrl: 'https://twitter.com/BiteeuExchange'
  //   }
  // },
  // {
  //   path: 'instagram',
  //   canActivate: [RedirectGuard],
  //   component: RedirectGuard,
  //   data: {
  //     externalUrl: 'https://instagram.com/biteeu_exchange?utm_source=ig_profile_share&igshid=1jxb6yl09mmea'
  //   }
  // },
  // {
  //   path: 'linkedin',
  //   canActivate: [RedirectGuard],
  //   component: RedirectGuard,
  //   data: {
  //     externalUrl: 'https://www.linkedin.com/company/biteeu/?viewAsMember=true'
  //   }
  // },
  // {
  //   path: 'telegram',
  //   canActivate: [RedirectGuard],
  //   component: RedirectGuard,
  //   data: {
  //     externalUrl: 'https://t.me/biteeu_chat'
  //   }
  // },
  // {
  //   path: 'user-settings', component: ProfileComponent, canActivate: [AuthGuard],
  //   children: [
  //     {path: '', pathMatch: 'full', redirectTo: 'wallets'},
  //     {path: 'wallets', component: WalletsComponent},
  //     {path: 'bcxg', component: BcxgComponent},
  //     {path: 'my-activity', component: MyActivityComponent},
  //     {path: 'my-profile', component: MyProfileComponent},
  //     {path: 'identity-verification', component: IdentityVerificationComponent},
  //     {path: 'enable-account', component: EnableAccountComponent},
  //     {path: 'password-settings', component: PasswordComponent},
  //     {path: 'two-factor-auth-settings', component: TwoFactorAuthComponent},
  //     {path: 'ip-whitelist', component: IpWhitelistComponent}, //  CLOSED
  //     {path: 'withdraw-whitelist', component: WithdrawalWhitelistComponent}, //  CLOSED
  //     {path: 'notifications', component: NotificationsComponent},
  //     {path: 'advcash', component: AdvcashComponent},
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [DataService]
})
export class AppRoutingModule {
}
