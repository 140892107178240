import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SvgService} from '../../../services/svg.service';
import {AuthService} from '../../../services/auth.service';
import {DataService} from '../../../services/data.service';
import {Compiler} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {ThemesModel} from '../../../models/themes.model';
import {TickerModel} from '../../../models/ticker.model';
import {CurrencyLastPriceClass} from '../../../models/currency-last-price.class';
import {MarketPriceInterface} from '../../../models/market-price.model';

@Component({
  selector: 'app-tickers',
  templateUrl: './tickers.component.html',
  styleUrls: ['./tickers.component.scss']
})
export class TickersComponent implements OnInit, OnDestroy {
  @Input() public isMinimized: boolean;
  @Output() public minimized = new EventEmitter<any>();
  public isLightTheme = false;
  public filterParam = 'all';
  public searchText;
  public sortParams = {
    sortByName: 'up',
    sortByLast: '',
    sortByVolume: '',
    sortBy24: ''
  };
  public tickers;
  public userId = undefined;
  public favMarkets;
  public objectKeys = Object.keys;
  public ThemesModel = ThemesModel;
  public activeList = false;
  public selectedMarket = 'BTC';
  public listMarkets = ['BTC', 'USDT', 'ETH'];
  public currencyMain;
  public currencyAdditional;
  public pair = '';

  private destroySubject$: Subject<void> = new Subject();

  constructor(
    public router: Router,
    public svgService: SvgService,
    public authService: AuthService,
    public dataService: DataService,
    private _compiler: Compiler
  ) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnInit() {
    this.pair = this.dataService.market;
    this.currencyMain = this.dataService.market.split('-')[1];
    this.currencyAdditional = this.dataService.market.split('-')[0];
    this.dataService.getMarketPairNewEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.pair = this.dataService.market;
        this.currencyMain = this.dataService.market.split('-')[1];
        this.currencyAdditional = this.dataService.market.split('-')[0];
      });
    if (this.authService.isLoggedIn) {
      this.getUserId();
    }
    this.getTickHistory();
    this.getTickers();
    if (this.authService.isLoggedIn) {
      this.getFavoriteMarkets();
    }
    this.dataService.SubscribeToUpdateFavs()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((data: any) => {
        if (data.u) {
          this.getFavoriteMarkets();
        }
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public getUserId() {
    this.userId = this.dataService.getUserId() ? this.dataService.getUserId() : +localStorage.getItem('visible-set');
    if (!this.userId) {
      this.dataService.getUserProfileFromServer();
    }
    this.dataService.getUserIdEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((response: number) => {
        this.userId = response;
      });
  }

  private getTickHistory() {
    this.dataService.getMarketsList()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => {
        this.tickers = Array.from(res.reduce(
          (acc, ticker) => acc.set(ticker.MarketName.split('-')[1], {
              ...(acc.get(ticker.MarketName.split('-')[1]) || {}),
              name: ticker.MarketName.split('-')[1],
              [ticker.MarketName.split('-')[0]]: {
                Last: ticker.Last,
                BaseVolume: ticker.BaseVolume24,
                h24: ticker.percentChange,
              }
            }
          ), new Map()
        ).values());
      });
  }

  private getTickers() {
    this.dataService.SubscribeToSummaryDeltas()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res) => {
        this.tickers = Array.from(res.reduce(
          (acc, ticker) => acc.set(ticker.MarketName.split('-')[1], {
              ...(acc.get(ticker.MarketName.split('-')[1]) || {}),
              name: ticker.MarketName.split('-')[1],
              [ticker.MarketName.split('-')[0]]: {
                Last: ticker.Last,
                BaseVolume: ticker.BaseVolume24,
                h24: ticker.percentChange,
              }
            }
          ), new Map()
        ).values());

        const market = this.tickers.find(ticker => ticker.MarketName === `${this.currencyAdditional} - ${this.currencyMain}`);
        if (market && this.currencyAdditional && this.pair && this.currencyMain) {
          const currencyLastPrice = new CurrencyLastPriceClass(
            this.pair,
            this.currencyMain,
            this.currencyAdditional,
            market.Last
          );
          this.dataService.setСurrencyLastPrice(currencyLastPrice);
          this.dataService.passСurrencyLastPrice(currencyLastPrice);
        }
      });
  }

  private getFavoriteMarkets() {
    this.dataService.getFavoriteMarkets()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => {
        this.favMarkets = res;
      });
  }

  public toggleFav(market, isFav) {
    const data = {market_name: market};
    if (isFav) {
      this.dataService.removeFromFavoriteMarkets(data)
        .subscribe();
    } else {
      this.dataService.addToFavoriteMarkets(data).subscribe();
    }
  }

  public toggleSort(param) {
    if (this.sortParams[param] === 'up') {
      this.sortParams[param] = 'down';
    } else if (this.sortParams[param] === 'down') {
      this.sortParams[param] = '';
    } else {
      this.sortParams[param] = 'up';
    }
  }

  public navigateToTrades(first, last): void {
    const pair = last + '-' + first.name;
    this.reloadDataForNewPairAtMarketPage(pair);
  }

  private reloadDataForNewPairAtMarketPage(pair: string) {
    this.dataService.passExchangeAmount(' ');
    this.dataService.setExchangeAmount(' ');
    this.dataService.passExchangePrice(' ');
    this.dataService.setExchangePrice(' ');
    try {
      this.dataService.leaveRoom(); //   trades
      this.dataService.leaveRoomUserId('' + this.userId); //   trades
      this.dataService.storePair(pair);
      // window.location.reload();
      this._compiler.clearCache();
      this.dataService.joinRoom(); //   trades
      if (this.authService.isLoggedIn) {
        if (this.dataService.getUserId()) {//   trades
          this.dataService.joinRoomUserId('' + this.dataService.getUserId());
        } else {//   trades
          this.dataService.getUserProfileFromServer();
          this.dataService.getUserIdEmitter()
            .pipe(takeUntil(this.destroySubject$))
            .subscribe((userId: number) => {
              this.dataService.joinRoomUserId('' + userId);
            });
        }
      }
      this.dataService.passMarketPairNew(pair); //   market-depth, order-book
      this.dataService.getMarketMinOrderAmount(); // exchange-calculator
    } catch (e) {
      // console.log(e);
    }
  }

  public toggleClass(): void {
    this.activeList = !this.activeList;
  }

  setMarket(market) {
    this.selectedMarket = market;
  }
}
